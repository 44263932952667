.input-container {
  width: 300px;
}

.input-container label.input-label {
  width: 100%;
  padding: 0px 0px 8px 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #1D2433;
  float: left;
}

.input-container input.text-input {
  background: var(--color-white);
  border: 1px solid var(--color-stroke-300);
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  color: var(--color-text-icon-primary);
  float: left;
}

.input-container input.text-input.xsmall {
  width: calc(100% - 16px);
  padding: 3px 7px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 4px;
}

.input-container input.text-input.base {
  width: calc(100% - 16px);
  padding: 7px 7px;
  font-size: 16px;
  line-height: 24px;
  border-radius: 8px;
}

.input-container input.text-input.xsmall:focus {
  width: calc(100% - 16px);
  padding: 3px 7px;
  outline: none !important;
  border: 1px solid var(--color-info);
}

.input-container input.text-input.base:focus {
  width: calc(100% - 16px);
  padding: 6px 6px;
  outline: none !important;
  border: 2px solid var(--color-info);
}

.input-container input.text-input::placeholder {
  color: var(--color-text-icon-secondary);
}

.input-container input.text-input:disabled {
  background: var(--color-disabled);
}

.input-container input.text-input.error {
  border: 1px solid var(--color-danger-700);
}

.input-container input.text-input.xsmall.error:focus {
  width: calc(100% - 16px);
  padding: 3px 7px;
  outline: none !important;
  border: 1px solid var(--color-danger-700);
}

.input-container input.text-input.base.error:focus {
  width: calc(100% - 16px);
  padding: 6px 6px;
  outline: none !important;
  border: 2px solid var(--color-danger-700);
}

.input-container .error-message-container {
  width: calc(100% - 8px);
  padding: 4px;
  margin-top: 4px;
  float: left;
  background-color: var(--color-danger-100);
}

.input-container .error-message-container .warning-icon {
  width: 16px;
  height: 16px;
  float: left;
  background: url("../../../assets/icons/information/information-critical.svg") center center no-repeat;
  background-size: cover;
}

.input-container .error-message-container p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-text-icon-primary);
}