.extra-information-area {
  width: 100%;
  float: left;
  margin-bottom: 24px;
  position: relative;
}

.extra-information-area .engagements-container {
  width: 900px;
  padding: 12px 16px;
  float: left;
  background: var(--color-white);
  border: 1px solid var(--color-neutral-300);
  border-radius: 6px;
}

.extra-information-area .engagements-container .engagement-description {
  width: 352px;
  float: left;
  margin-right: 16px;
}

.extra-information-area .engagements-container .engagement-description p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text-icon-disabled);
}

.extra-information-area .engagements-container .engagement-item {
  width: 250px;
  float: left;
  padding: 4px;
  background: var(--color-engagement-success-300);
  border-radius: 4px;
  margin-right: 16px;
}

.extra-information-area .engagements-container .engagement-item:last-child {
  margin-right: 0px;
}

.extra-information-area .engagements-container .engagement-item .engagement-icon {
  width: 24px;
  height: 24px;
  float: left;
  background: var(--color-engagement-success-400) url("../../../../assets/icons/mail/mail-engagement-success.svg") no-repeat center center;
  background-size: auto;
  border-radius: 50%;
}

.extra-information-area .engagements-container .engagement-item .engagement-content {
  width: calc(100% - 32px);
  float: left;
  margin-left: 8px;
}

.extra-information-area .engagements-container .engagement-item .engagement-content .label {
  width: 100%;
  float: left;
}

.extra-information-area .engagements-container .engagement-item .engagement-content .label h4 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-text-icon-disabled);
}

.extra-information-area .engagements-container .engagement-item .engagement-content .title {
  width: 100%;
  float: left;
}

.extra-information-area .engagements-container .engagement-item .engagement-content .title p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-interaction-primary-700);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.extra-information-area .engagements-container .engagement-item .engagement-content .title a {
  text-decoration: none;
}

.extra-information-area .engagements-container .engagement-item .engagement-content .date {
  width: 100%;
  float: left;
}

.extra-information-area .engagements-container .engagement-item .engagement-content .date p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text-icon-disabled);
}

.proxy-advisor-information-area {
  width: 100%;
  float: left;
  background: var(--color-white);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}

.proxy-advisor-information-area .section {
  width: calc(100% - 16px);
  float: left;
  position: relative;
  padding: 68px 8px 0px 8px;
}

.proxy-advisor-information-area .section .section-title {
  position: absolute;
  top: 32px;
  left: 0px;
  padding: 6px 8px;
  background-color: var(--color-interaction-primary-100);
  border-left: 4px solid var(--color-section-ribbon);
}

.proxy-advisor-information-area .section .section-title h3 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: var(--color-text-icon-primary);
  float: left;
}

.proxy-advisor-information-area .section .section-title .tooltip-icon {
  width: 13px;
  height: 13px;
  float: left;
  margin: 2px 0px 1px 8px;
  background: url("../../../../assets/icons/information/information-tooltip.svg") no-repeat center center;
  background-size: cover;
  cursor: pointer;
}

.proxy-advisor-information-area .section .action-buttons {
  position: absolute;
  top: 32px;
  right: 32px;
}

.proxy-advisor-information-area .section .action-buttons div {
  margin-right: 8px;
}

.proxy-advisor-information-area .section .action-buttons div:last-child {
  margin-right: 0px;
}

.proxy-advisor-information-area .section .content {
  width: calc(100% - 48px);
  float: left;
  padding: 24px;
}

.proxy-advisor-information-area .section .content.edit-mode {
  background-color: var(--color-interaction-primary-100);
}

.proxy-advisor-information-area .section .separator {
  width: calc(100% - 48px);
  float: left;
  border-bottom: 1px solid var(--color-neutral-300);
  margin: 8px 24px 0px 24px;
}

.proxy-advisor-information-area .section:last-child .separator {
  border-bottom: none;
}