.row.header {
  width: 100%;
  float: left;
  background-color: var(--color-gray-50);
}

.row.header:first-child {
  border-radius: 8px 8px 0px 0px;
  border-bottom: 1px solid var(--color-gray-200);
}

.row.body {
  width: 100%;
  float: left;
  background-color: var(--color-white);
  border-bottom: 1px solid var(--color-gray-200);
}

.row.body:last-child {
  border-bottom: none;
  border-radius: 0px 0px 8px 8px;
}