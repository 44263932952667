.temporary-filler.wide {
  width: 256px;
  height: 100%;
  float: left;
}

.temporary-filler.narrow {
  width: 56px;
  height: 100%;
  float: left;
}

.side-nav-bar-container.wide {
  width: 256px;
  height: 100%;
  background-color: var(--color-brand-color-primary-dark-blue);
  top: 0px;
  left: 0px;
  position: fixed;
  z-index: 30;
}

.side-nav-bar-container.wide .logo {
  height: 56px;
  background: url("../../../assets/logo/logo.svg") no-repeat no-repeat left;
  /*background-size: cover;*/
  background-size: initial;
  margin-left: -7px;
}

.side-nav-bar-container.wide .mizuho-logo {
  height: 56px;
  background: url("../../../assets/logo/mizuho/mizuho_wide_logo.png") no-repeat no-repeat left;
  background-size: contain;
  margin-left: 4px;
}

.side-nav-bar-container.wide .uptade-type-button {
  width: 22px;
  height: 22px;
  border: 1px solid var(--color-stroke-300);
  border-radius: 50%;
  background: url("../../../assets/icons/arrow-left.svg") no-repeat center center;
  background-color: var(--color-brand-color-primary-red);
  background-size: initial;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  position: absolute;
  top: 53px;
  left: 244px;
  cursor: pointer;
  display: none;
}

.side-nav-bar-container.wide:hover .uptade-type-button {
  display: block;
}

.side-nav-bar-container.wide .top-menu {
  width: 100%;
  margin-top: 48px;
  float: left;
}

.side-nav-bar-container.wide .top-menu .item {
  width: calc(100% - 16px);
  padding: 8px 16px 8px 0px;
  cursor: pointer;
  float: left;
}

.side-nav-bar-container.wide .top-menu .item:hover {
  background-color: #374151;
}

.side-nav-bar-container.wide .top-menu .item .ribbon {
  width: 4px;
  height: 28px;
  margin-right: 12px;
  background-color: var(--color-brand-color-primary-dark-blue);
  float: left;
}

.side-nav-bar-container.wide .top-menu .item:hover .ribbon {
  background-color: var(--color-brand-color-primary-red);
}

.side-nav-bar-container.wide .top-menu .item.selected {
  background-color: #374151;
}

.side-nav-bar-container.wide .top-menu .item.selected .ribbon {
  background-color: var(--color-brand-color-primary-red);
}

.side-nav-bar-container.wide .top-menu .item .text {
  float: left;
  margin: 4px 0px 4px 12px;
}

.side-nav-bar-container.wide .top-menu .item .text p {
  padding: 0px;
  margin: 0px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-white);
}

.side-nav-bar-container.wide .top-menu .item:hover .text p {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.side-nav-bar-container.wide .top-menu .item.selected .text p {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.side-nav-bar-container.wide .top-menu .item .icon {
  width: 20px;
  height: 20px;
  margin: 4px 0px;
  float: left;
}

.side-nav-bar-container.wide .top-menu .item .icon.dashboard {
  background: url("../../../assets/icons/dashboard.svg") no-repeat center center;
  background-size: contain;
}

.side-nav-bar-container.wide .top-menu .item .icon.investors {
  background: url("../../../assets/icons/investors.svg") no-repeat center center;
  background-size: contain;
}

.side-nav-bar-container.wide .top-menu .item .icon.proxy-advisors {
  background: url("../../../assets/icons/proxy-advisors.svg") no-repeat center center;
  background-size: contain;
}

.side-nav-bar-container.wide .top-menu .item .icon.esg-topics {
  background: url("../../../assets/icons/esg-topics.svg") no-repeat center center;
  background-size: contain;
}

.side-nav-bar-container.wide .top-menu .item .icon.engagements {
  background: url("../../../assets/icons/engagements.svg") no-repeat center center;
  background-size: contain;
}

.side-nav-bar-container.wide .top-menu .item .icon.docusearch {
  background: url("../../../assets/icons/docusearch.svg") no-repeat center center;
  background-size: contain;
}

.side-nav-bar-container.wide .top-menu .item .icon.quickvote {
  background: url("../../../assets/icons/quickvote.svg") no-repeat center center;
  background-size: contain;
}

.side-nav-bar-container.wide .top-menu .item .icon.research {
  background: url("../../../assets/icons/research.svg") no-repeat center center;
  background-size: contain;
}

.side-nav-bar-container.wide .top-menu .item .icon.voting-projections {
  background: url("../../../assets/icons/voting-projections.svg") no-repeat center center;
  background-size: contain;
}

.side-nav-bar-container.wide .top-menu .item .icon.my-clients {
  background: url("../../../assets/icons/my-clients.svg") no-repeat center center;
  background-size: contain;
}

.side-nav-bar-container.wide .top-menu .item .icon.notifications {
  background: url("../../../assets/icons/notifications.svg") no-repeat center center;
  background-size: contain;
}

.side-nav-bar-container.wide .bottom-menu {
  width: 100%;
  position: absolute;
  bottom: 0px;
}

.side-nav-bar-container.wide .bottom-menu .item {
  width: calc(100% - 16px);
  padding: 8px 16px 8px 0px;
  cursor: pointer;
  float: left;
}

.side-nav-bar-container.wide .bottom-menu .item:hover {
  background-color: #374151;
}

.side-nav-bar-container.wide .bottom-menu .item .ribbon {
  width: 4px;
  height: 28px;
  margin-right: 12px;
  background-color: var(--color-brand-color-primary-dark-blue);
  float: left;
}

.side-nav-bar-container.wide .bottom-menu .item:hover .ribbon {
  background-color: var(--color-brand-color-primary-red);
}

.side-nav-bar-container.wide .bottom-menu .item.selected {
  background-color: #374151;
}

.side-nav-bar-container.wide .bottom-menu .item.selected .ribbon {
  background-color: var(--color-brand-color-primary-red);
}

.side-nav-bar-container.wide .bottom-menu .item .text {
  float: left;
  margin: 4px 0px 4px 12px;
}

.side-nav-bar-container.wide .bottom-menu .item .text p {
  padding: 0px;
  margin: 0px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-white);
}

.side-nav-bar-container.wide .bottom-menu .item:hover .text p {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.side-nav-bar-container.wide .bottom-menu .item.selected .text p {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.side-nav-bar-container.wide .bottom-menu .item .icon {
  width: 20px;
  height: 20px;
  margin: 4px 0px;
  float: left;
}

.side-nav-bar-container.wide .bottom-menu .item .icon.help {
  background: url("../../../assets/icons/help.svg") no-repeat center center;
  background-size: contain;
}

.side-nav-bar-container.wide .bottom-menu .item .icon.logout {
  background: url("../../../assets/icons/logout.svg") no-repeat center center;
  background-size: contain;
}

.side-nav-bar-container.wide .bottom-menu .profile {
  width: calc(100% - 16px);
  padding: 6px 16px 6px 0px;
  cursor: pointer;
  float: left;
}

.side-nav-bar-container.wide .bottom-menu .profile:hover {
  background-color: #374151;
}

.side-nav-bar-container.wide .bottom-menu .profile .ribbon {
  width: 4px;
  height: 28px;
  margin: 2px 8px 2px 0px;
  background-color: var(--color-brand-color-primary-dark-blue);
  float: left;
}

.side-nav-bar-container.wide .bottom-menu .profile:hover .ribbon {
  background-color: var(--color-brand-color-primary-red);
}

.side-nav-bar-container.wide .bottom-menu .profile.selected {
  background-color: #374151;
}

.side-nav-bar-container.wide .bottom-menu .profile.selected .ribbon {
  background-color: var(--color-brand-color-primary-red);
}

.side-nav-bar-container.wide .bottom-menu .profile .photo {
  width: 30px;
  height: 30px;
  border: 1px solid var(--color-stroke-300);
  border-radius: 50%;
  margin-right: 8px;
  float: left;
}

.side-nav-bar-container.wide .bottom-menu .profile .info {
  float: left;
}

.side-nav-bar-container.wide .bottom-menu .profile .info .name p {
  padding: 0px;
  margin: 0px;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-white);
}

.side-nav-bar-container.wide .bottom-menu .profile .info .role p {
  padding: 0px;
  margin: 0px;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-white-secondary);
}

.side-nav-bar-container.narrow {
  width: 56px;
  height: 100%;
  background-color: var(--color-brand-color-primary-dark-blue);
  top: 0px;
  left: 0px;
  position: fixed;
  z-index: 30;
}

.side-nav-bar-container.narrow .logo {
  height: 56px;
  background: url("../../../assets/logo/logo-icon.svg") no-repeat center center;
  background-size: initial;
}

.side-nav-bar-container.narrow .mizuho-logo {
  height: 56px;
  background: url("../../../assets/logo/mizuho/mizuho_square_logo.png") no-repeat center center;
  background-size: contain;
}

.side-nav-bar-container.narrow .uptade-type-button {
  width: 22px;
  height: 22px;
  border: 1px solid var(--color-stroke-300);
  border-radius: 50%;
  background: url("../../../assets/icons/arrow-right.svg") no-repeat center center;
  background-color: var(--color-brand-color-primary-red);
  background-size: initial;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  position: absolute;
  top: 53px;
  left: 44px;
  cursor: pointer;
  display: none;
}

.side-nav-bar-container.narrow:hover .uptade-type-button {
  display: block;
}

.side-nav-bar-container.narrow .top-menu {
  width: 100%;
  margin-top: 48px;
  float: left;
}

.side-nav-bar-container.narrow .top-menu .item {
  width: calc(100% - 16px);
  padding: 8px 16px 8px 0px;
  cursor: pointer;
  float: left;
}

.side-nav-bar-container.narrow .top-menu .item:hover {
  background-color: #374151;
}

.side-nav-bar-container.narrow .top-menu .item .ribbon {
  width: 4px;
  height: 28px;
  margin-right: 12px;
  background-color: var(--color-brand-color-primary-dark-blue);
  float: left;
}

.side-nav-bar-container.narrow .top-menu .item:hover .ribbon {
  background-color: var(--color-brand-color-primary-red);
}

.side-nav-bar-container.narrow .top-menu .item.selected {
  background-color: #374151;
}

.side-nav-bar-container.narrow .top-menu .item.selected .ribbon {
  background-color: var(--color-brand-color-primary-red);
}

.side-nav-bar-container.narrow .top-menu .item .icon {
  width: 20px;
  height: 20px;
  margin: 4px 0px;
  float: left;
}

.side-nav-bar-container.narrow .top-menu .item .icon.dashboard {
  background: url("../../../assets/icons/dashboard.svg") no-repeat center center;
  background-size: contain;
}

.side-nav-bar-container.narrow .top-menu .item .icon.investors {
  background: url("../../../assets/icons/investors.svg") no-repeat center center;
  background-size: contain;
}

.side-nav-bar-container.narrow .top-menu .item .icon.proxy-advisors {
  background: url("../../../assets/icons/proxy-advisors.svg") no-repeat center center;
  background-size: contain;
}

.side-nav-bar-container.narrow .top-menu .item .icon.esg-topics {
  background: url("../../../assets/icons/esg-topics.svg") no-repeat center center;
  background-size: contain;
}

.side-nav-bar-container.narrow .top-menu .item .icon.engagements {
  background: url("../../../assets/icons/engagements.svg") no-repeat center center;
  background-size: contain;
}

.side-nav-bar-container.narrow .top-menu .item .icon.docusearch {
  background: url("../../../assets/icons/docusearch.svg") no-repeat center center;
  background-size: contain;
}

.side-nav-bar-container.narrow .top-menu .item .icon.quickvote {
  background: url("../../../assets/icons/quickvote.svg") no-repeat center center;
  background-size: contain;
}

.side-nav-bar-container.narrow .top-menu .item .icon.research {
  background: url("../../../assets/icons/research.svg") no-repeat center center;
  background-size: contain;
}

.side-nav-bar-container.narrow .top-menu .item .icon.voting-projections {
  background: url("../../../assets/icons/voting-projections.svg") no-repeat center center;
  background-size: contain;
}

.side-nav-bar-container.narrow .top-menu .item .icon.my-clients {
  background: url("../../../assets/icons/my-clients.svg") no-repeat center center;
  background-size: contain;
}

.side-nav-bar-container.narrow .top-menu .item .icon.notifications {
  background: url("../../../assets/icons/notifications.svg") no-repeat center center;
  background-size: contain;
}

.side-nav-bar-container.narrow .bottom-menu {
  width: 100%;
  position: absolute;
  bottom: 0px;
}

.side-nav-bar-container.narrow .bottom-menu .item {
  width: 100%;
  padding: 8px 0px 8px 0px;
  cursor: pointer;
  float: left;
}

.side-nav-bar-container.narrow .bottom-menu .item:hover {
  background-color: #374151;
}

.side-nav-bar-container.narrow .bottom-menu .item .ribbon {
  width: 4px;
  height: 28px;
  margin-right: 12px;
  background-color: var(--color-brand-color-primary-dark-blue);
  float: left;
}

.side-nav-bar-container.narrow .bottom-menu .item:hover .ribbon {
  background-color: var(--color-brand-color-primary-red);
}

.side-nav-bar-container.narrow .bottom-menu .item.selected {
  background-color: #374151;
}

.side-nav-bar-container.narrow .bottom-menu .item.selected .ribbon {
  background-color: var(--color-brand-color-primary-red);
}

.side-nav-bar-container.narrow .bottom-menu .item .icon {
  width: 20px;
  height: 20px;
  margin: 4px 0px;
  float: left;
}

.side-nav-bar-container.narrow .bottom-menu .item .icon.help {
  background: url("../../../assets/icons/help.svg") no-repeat center center;
  background-size: contain;
}

.side-nav-bar-container.narrow .bottom-menu .item .icon.logout {
  background: url("../../../assets/icons/logout.svg") no-repeat center center;
  background-size: contain;
}

.side-nav-bar-container.narrow .bottom-menu .profile {
  width: 100%;
  padding: 6px 0px 6px 0px;
  cursor: pointer;
  float: left;
}

.side-nav-bar-container.narrow .bottom-menu .profile:hover {
  background-color: #374151;
}

.side-nav-bar-container.narrow .bottom-menu .profile .ribbon {
  width: 4px;
  height: 28px;
  margin: 2px 8px 2px 0px;
  background-color: var(--color-brand-color-primary-dark-blue);
  float: left;
}

.side-nav-bar-container.narrow .bottom-menu .profile:hover .ribbon {
  background-color: var(--color-brand-color-primary-red);
}

.side-nav-bar-container.narrow .bottom-menu .profile.selected {
  background-color: #374151;
}

.side-nav-bar-container.narrow .bottom-menu .profile.selected .ribbon {
  background-color: var(--color-brand-color-primary-red);
}

.side-nav-bar-container.narrow .bottom-menu .profile .photo {
  width: 30px;
  height: 30px;
  border: 1px solid var(--color-stroke-300);
  border-radius: 50%;
  float: left;
}