.col-item {
  margin-right: 16px;
}

.col-item:last-child {
  margin-right: 0px;
}

.single-col {
  width: calc(calc(100% - 64px) / 5);
  float: left;
}

.double-col {
  width: calc(calc(calc(calc(100% - 64px) / 5) * 2) + 16px);
  float: left;
}

.full-size-col {
  width: 100%;
  float: left;
}

.label {
  width: 100%;
  float: left;
  margin-bottom: 8px;
}

.label h4 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: var(--color-text-icon-primary);
  float: left;
}

.label .tooltip-icon {
  width: 13px;
  height: 13px;
  float: left;
  margin: 2px 0px 1px 8px;
  background: url("../../../../../assets/icons/information/information-tooltip.svg") no-repeat center center;
  background-size: cover;
  cursor: pointer;
}

.value {
  width: 100%;
  float: left;
}

.value .text p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-icon-secondary);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.value .url p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-icon-secondary);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.value .url p a {
  color: var(--color-interaction-primary-700);
  text-decoration: none;
}

.value .text-block {
  width: calc(100% - 24px);
  height: 168px;
  padding: 16px 12px 16px 12px;
  background-color: var(--color-neutral-200);
  overflow: auto;
}

.value .text-block p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-icon-secondary);
}

.value .rich-text-block {
  width: calc(100% - 18px);
  height: 150px;
  padding: 8px;
  float: left;
  border: 1px solid var(--color-stroke-300);
  border-radius: 8px;
  font-family: 'Montserrat';
  color: var(--color-text-icon-primary);
  overflow: auto;
  background-color: var(--color-white);
}

.value .rich-text-block.short {
  height: 197px;
}

.value .rich-text-block.long {
  height: 312px;
}

.value .rich-text-block p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-icon-secondary);
}