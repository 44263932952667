.meeting-request-container {
  width: 100%;
  float: left;
}

.meeting-request-container .header {
  width: 100%;
  float: left;
}

.meeting-request-container .header .form-title {
  width: 100%;
  float: left;
  margin-bottom: 24px;
}

.meeting-request-container .header .form-title h2 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -1px;
}

.meeting-request-container .header .meeting-request-title-container {
  width: 784px;
  float: left;
}

.meeting-request-container .header .title {
  float: left;
  margin-right: 16px;
}

.meeting-request-container .header .title h2 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -1px;
  color: var(--color-black);
}

.meeting-request-container .header .type-chip {
  float: left;
  margin: 8px 0px;
  padding: 4px 8px;
  background-color: var(--color-neutral-300);
  border-radius: 4px;
}

.meeting-request-container .header .type-chip .label p {
  padding: 0px;
  margin: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: var(--color-text-icon-primary);
}

.meeting-request-container .header .meeting-request-engagement-list-buttons {
  float: right;
  margin: 5px 0px;
}

.meeting-request-container .header .meeting-request-engagement-list-buttons .right-buttons {
  float: right;
}

.meeting-request-container .header .meeting-request-engagement-list-buttons .right-buttons div {
  margin-right: 8px;
}

.meeting-request-container .header .meeting-request-engagement-list-buttons .right-buttons div:last-child {
  margin-right: 0px;
}

.meeting-request-container .body .row {
  width: 100%;
  float: left;
  margin-bottom: 24px;
}

.meeting-request-container .meeting-request-name-container { 
  width: 600px;
  float: left;
  padding: 0px 16px 32px 0px;
  border-bottom: 1px solid var(--color-stroke-300);
}

.meeting-request-container .action-button-container { 
  width: 500px;
  float: left;
  padding: 24px 0px 32px 16px;
  border-bottom: 1px solid var(--color-stroke-300);
}

.meeting-request-container .action-button-container div {
  margin-left: 8px;
  float: right;
}

.meeting-request-container .action-button-container div:last-child {
  margin-left: 0px;
}

.meeting-request-container .meeting-request-email-subject-body-container { 
  width: 600px;
  float: left;
  margin-right: 16px;
}

.meeting-request-container .meeting-request-email-subject-body-container .email-subject {
  width: 100%;
  float: left;
  margin-bottom: 24px;
}

.meeting-request-container .meeting-request-email-subject-body-container .email-body {
  width: 100%;
  float: left;
}

.meeting-request-container .meeting-request-email-cc-container { 
  width: 500px;
  float: left;
  margin-left: 16px;
}

.meeting-request-container .meeting-request-email-cc-container .added-cc-contacts-container {
  width: 100%;
  float: left;
  margin-top: 8px;
}

.meeting-request-container .meeting-request-email-cc-container .added-cc-contacts-container .note {
  width: 100%;
  float: left;
}

.meeting-request-container .meeting-request-email-cc-container .added-cc-contacts-container .note p {
  padding: 0px;
  margin: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-text-icon-primary);
}

.meeting-request-container .meeting-request-email-cc-container .added-cc-contacts-container .added-cc-contact {
  max-width: calc(100% - 24px);
  padding: 11px;
  border: 1px solid var(--color-stroke-300);
  background-color: var(--color-interaction-primary-100);
  border-radius: 4px;
  float: left;
  margin: 8px 8px 0px 0px;
}

.meeting-request-container .meeting-request-email-cc-container .added-cc-contacts-container .added-cc-contact .added-cc-contact-text {
  width: calc(100% - 16px);
  float: left;
}

.meeting-request-container .meeting-request-email-cc-container .added-cc-contacts-container .added-cc-contact .added-cc-contact-text h3 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: var(--color-text-icon-primary);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.meeting-request-container .meeting-request-email-cc-container .added-cc-contacts-container .added-cc-contact .remove-button {
  width: 8px;
  height: 8px;
  background: url("../../../assets/icons/cross.svg") center center no-repeat;
  background-size: cover;
  float: left;
  margin: 5px 0px 5px 8px;
  cursor: pointer;
}

.meeting-request-container .investor-table {
  width: 600px;
  float: left;
  margin-right: 16px;
  position: relative;
}

.meeting-request-container .investor-table .investor-combobox-container {
  width: 100%;
  float: left;
  position: absolute;
  top: 115px;
  left: 41px;
}

.meeting-request-container .proxy-advisor-table {
  width: 500px;
  float: left;
  margin-left: 16px;
  position: relative;
}

.meeting-request-container .proxy-advisor-table.no-margin {
  margin-left: 0px;
  margin-bottom: 60px;
}

.meeting-request-container .proxy-advisor-table .proxy-advisor-combobox-container {
  width: 100%;
  float: left;
  position: absolute;
  top: 115px;
  left: 41px;
}


.engagement-meeting-request-proxy-advisor-list-container {
  width: 100%;
  float: left;
  margin-top: 24px;
}

.engagement-meeting-request-investor-list-container {
  width: 100%;
  float: left;
  margin-top: 24px;
}