.button-container {
  /* width: 160px; */
  float: left;
}

.button-container button {
  width: 100%;
  float: left;
  cursor: pointer;
}

.button-container button.xsmall {
  border-radius: 4px;
  padding: 7px 12px;
}

.button-container button.small {
  border-radius: 6px;
  padding: 7px 40px;
}

.button-container button.large {
  border-radius: 6px;
  padding: 10px 40px;
}

.button-container button.base {
  border-radius: 6px;
  padding: 11px 20px;
}

.button-container button.delete:disabled .content .text p {
  color: var(--color-text-icon-disabled);
}

.button-container button .content {
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.button-container button .content .text p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  float: left;
}

.button-container button.xsmall .content .text p {
  padding: 1px 0px;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
}

.button-container button.small .content .text p {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.button-container button.large .content .text p {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
}

.button-container button.base .content .text p {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
}

.button-container button.primary {
  border: 1px solid var(--color-interaction-primary-700);
  background-color: var(--color-interaction-primary-700);
}

.button-container button.primary .content .text p {
  color: var(--color-white);
}

.button-container button.primary:hover {
  border: 1px solid var(--color-interaction-primary-800);
  background-color: var(--color-interaction-primary-800);
}

.button-container button.primary:disabled {
  border: 1px solid var(--color-neutral-300);
  background-color: var(--color-neutral-300);
  cursor: default;
}

.button-container button.primary:disabled .content .text p {
  color: var(--color-text-icon-disabled);
}

.button-container button.secondary {
  border: 1px solid var(--color-interaction-primary-300);
  background-color: var(--color-interaction-primary-200);
}

.button-container button.secondary .content .text p {
  color: var(--color-interaction-primary-700);
}

.button-container button.secondary:hover {
  border: 1px solid var(--color-interaction-primary-300);
  background-color: var(--color-interaction-primary-300);
}

.button-container button.secondary:disabled {
  border: 1px solid var(--color-neutral-200);
  background-color: var(--color-neutral-200);
  cursor: default;
}

.button-container button.secondary:disabled .content .text p {
  color: var(--color-text-icon-disabled);
}

.button-container button.cancel {
  border: 1px solid var(--color-stroke-300);
  background-color: var(--color-white);
}

.button-container button.cancel .content .text p {
  color: var(--color-text-icon-primary);
}

.button-container button.cancel:hover {
  border: 1px solid var(--color-stroke-300);
  background-color: var(--color-neutral-200);
}

.button-container button.cancel:disabled {
  border: 1px solid var(--color-neutral-300);
  background-color: var(--color-neutral-200);
  cursor: default;
}

.button-container button.cancel:disabled .content .text p {
  color: var(--color-text-icon-disabled);
}

.button-container button.delete {
  border: 1px solid var(--color-danger-700);
  background-color: var(--color-danger-700);
}

.button-container button.delete .content .text p {
  color: var(--color-white);
}

.button-container button.delete:hover {
  border: 1px solid var(--color-danger-800);
  background-color: var(--color-danger-800);
}

.button-container button.delete:disabled {
  border: 1px solid var(--color-neutral-300);
  background-color: var(--color-neutral-300);
  cursor: default;
}

.button-container button.xsmall .content .icon-container {
  width: 14px;
  height: 14px;
  float: left;
}

.button-container button.small .content .icon-container {
  width: 14px;
  height: 14px;
  float: left;
}

.button-container button.large .content .icon-container {
  width: 16px;
  height: 16px;
  float: left;
}

.button-container button.base .content .icon-container {
  width: 16px;
  height: 16px;
  float: left;
}

.button-container button .content .icon-container.left {
  margin-right: 8px;
}

.button-container button .content .icon-container.right {
  margin-left: 8px;
}

.button-container button.primary .content .icon-container.upload {
  background: url("../../../assets/icons/upload/upload-white.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.primary:disabled .content .icon-container.upload {
  background: url("../../../assets/icons/upload/upload-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.secondary .content .icon-container.upload {
  background: url("../../../assets/icons/upload/upload-interaction-primary-700.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.secondary:disabled .content .icon-container.upload {
  background: url("../../../assets/icons/upload/upload-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.cancel .content .icon-container.upload {
  background: url("../../../assets/icons/upload/upload-text-icon-primary.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.cancel:disabled .content .icon-container.upload {
  background: url("../../../assets/icons/upload/upload-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.delete .content .icon-container.upload {
  background: url("../../../assets/icons/upload/upload-white.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.delete:disabled .content .icon-container.upload {
  background: url("../../../assets/icons/upload/upload-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.primary .content .icon-container.download {
  background: url("../../../assets/icons/download/download-white.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.primary:disabled .content .icon-container.download {
  background: url("../../../assets/icons/download/download-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.secondary .content .icon-container.download {
  background: url("../../../assets/icons/download/download-interaction-primary-700.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.secondary:disabled .content .icon-container.download {
  background: url("../../../assets/icons/download/download-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.cancel .content .icon-container.download {
  background: url("../../../assets/icons/download/download-text-icon-primary.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.cancel:disabled .content .icon-container.download {
  background: url("../../../assets/icons/download/download-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.delete .content .icon-container.download {
  background: url("../../../assets/icons/download/download-white.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.delete:disabled .content .icon-container.download {
  background: url("../../../assets/icons/download/download-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.primary .content .icon-container.edit {
  background: url("../../../assets/icons/edit/edit-white.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.primary:disabled .content .icon-container.edit {
  background: url("../../../assets/icons/edit/edit-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.secondary .content .icon-container.edit {
  background: url("../../../assets/icons/edit/edit-interaction-primary-700.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.secondary:disabled .content .icon-container.edit {
  background: url("../../../assets/icons/edit/edit-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.cancel .content .icon-container.edit {
  background: url("../../../assets/icons/edit/edit-text-icon-primary.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.cancel:disabled .content .icon-container.edit {
  background: url("../../../assets/icons/edit/edit-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.delete .content .icon-container.edit {
  background: url("../../../assets/icons/edit/edit-white.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.delete:disabled .content .icon-container.edit {
  background: url("../../../assets/icons/edit/edit-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.primary .content .icon-container.plus {
  background: url("../../../assets/icons/plus/plus-white.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.primary:disabled .content .icon-container.plus {
  background: url("../../../assets/icons/plus/plus-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.secondary .content .icon-container.plus {
  background: url("../../../assets/icons/plus/plus-interaction-primary-700.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.secondary:disabled .content .icon-container.plus {
  background: url("../../../assets/icons/plus/plus-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.cancel .content .icon-container.plus {
  background: url("../../../assets/icons/plus/plus-text-icon-primary.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.cancel:disabled .content .icon-container.plus {
  background: url("../../../assets/icons/plus/plus-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.delete .content .icon-container.plus {
  background: url("../../../assets/icons/plus/plus-white.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.delete:disabled .content .icon-container.plus {
  background: url("../../../assets/icons/plus/plus-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.primary .content .icon-container.arrow-right {
  background: url("../../../assets/icons/arrow-right/arrow-right-white.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.primary:disabled .content .icon-container.arrow-right {
  background: url("../../../assets/icons/arrow-right/arrow-right-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.secondary .content .icon-container.arrow-right {
  background: url("../../../assets/icons/arrow-right/arrow-right-interaction-primary-700.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.secondary:disabled .content .icon-container.arrow-right {
  background: url("../../../assets/icons/arrow-right/arrow-right-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.cancel .content .icon-container.arrow-right {
  background: url("../../../assets/icons/arrow-right/arrow-right-text-icon-primary.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.cancel:disabled .content .icon-container.arrow-right {
  background: url("../../../assets/icons/arrow-right/arrow-right-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.delete .content .icon-container.arrow-right {
  background: url("../../../assets/icons/arrow-right/arrow-right-white.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.delete:disabled .content .icon-container.arrow-right {
  background: url("../../../assets/icons/arrow-right/arrow-right-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.primary .content .icon-container.arrow-left {
  background: url("../../../assets/icons/arrow-left/arrow-left-white.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.primary:disabled .content .icon-container.arrow-left {
  background: url("../../../assets/icons/arrow-left/arrow-left-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.secondary .content .icon-container.arrow-left {
  background: url("../../../assets/icons/arrow-left/arrow-left-interaction-primary-700.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.secondary:disabled .content .icon-container.arrow-left {
  background: url("../../../assets/icons/arrow-left/arrow-left-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.cancel .content .icon-container.arrow-left {
  background: url("../../../assets/icons/arrow-left/arrow-left-text-icon-primary.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.cancel:disabled .content .icon-container.arrow-left {
  background: url("../../../assets/icons/arrow-left/arrow-left-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.delete .content .icon-container.arrow-left {
  background: url("../../../assets/icons/arrow-left/arrow-left-white.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.delete:disabled .content .icon-container.arrow-left {
  background: url("../../../assets/icons/arrow-left/arrow-left-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.primary .content .icon-container.document {
  background: url("../../../assets/icons/document/document-white.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.primary:disabled .content .icon-container.document {
  background: url("../../../assets/icons/document/document-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.secondary .content .icon-container.document {
  background: url("../../../assets/icons/document/document-interaction-primary-700.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.secondary:disabled .content .icon-container.document {
  background: url("../../../assets/icons/document/document-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.cancel .content .icon-container.document {
  background: url("../../../assets/icons/document/document-text-icon-primary.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.cancel:disabled .content .icon-container.document {
  background: url("../../../assets/icons/document/document-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.delete .content .icon-container.document {
  background: url("../../../assets/icons/document/document-white.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.delete:disabled .content .icon-container.document {
  background: url("../../../assets/icons/document/document-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.primary .content .icon-container.continue {
  background: url("../../../assets/icons/continue/continue-white.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.primary:disabled .content .icon-container.continue {
  background: url("../../../assets/icons/continue/continue-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.secondary .content .icon-container.continue {
  background: url("../../../assets/icons/continue/continue-interaction-primary-700.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.secondary:disabled .content .icon-container.continue {
  background: url("../../../assets/icons/continue/continue-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.cancel .content .icon-container.continue {
  background: url("../../../assets/icons/continue/continue-text-icon-primary.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.cancel:disabled .content .icon-container.continue {
  background: url("../../../assets/icons/continue/continue-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.delete .content .icon-container.continue {
  background: url("../../../assets/icons/continue/continue-white.svg") center center no-repeat;
  background-size: contain;
}

.button-container button.delete:disabled .content .icon-container.continue {
  background: url("../../../assets/icons/continue/continue-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}