.input-container {
  width: 300px;
}

.input-container label.input-label {
  width: 100%;
  padding: 0px 0px 8px 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #1D2433;
  float: left;
}

.input-container .input-wrapper {
  width: 100%;
  position: relative;
  float: left;
}

.input-container input.text-input {
  background: var(--color-white);
  border: 1px solid var(--color-stroke-300);
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  color: var(--color-text-icon-primary);
  float: left;
  position: relative;
  z-index: 10;
}

.input-container input.text-input.xsmall {
  width: calc(100% - 16px);
  padding: 3px 7px 3px 7px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 4px;
}

.input-container input.text-input.xsmall.custom {
  width: calc(100% - 67px);
  padding: 3px 58px 3px 7px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 4px;
}

.input-container input.text-input.base {
  width: calc(100% - 16px);
  padding: 7px 7px;
  font-size: 16px;
  line-height: 24px;
  border-radius: 8px;
}

.input-container input.text-input.xsmall:focus {
  width: calc(100% - 16px);
  padding: 3px 7px 3px 7px;
  outline: none !important;
  border: 1px solid var(--color-info);
}

.input-container input.text-input.xsmall.custom:focus {
  width: calc(100% - 67px);
  padding: 3px 58px 3px 7px;
  outline: none !important;
  border: 1px solid var(--color-info);
}

.input-container input.text-input.base:focus {
  width: calc(100% - 14px);
  padding: 6px 6px;
  outline: none !important;
  border: 2px solid var(--color-info);
}

.input-container input.text-input::placeholder {
  color: var(--color-text-icon-secondary);
}

.input-container input.text-input:disabled {
  background: var(--color-disabled);
}

.input-container input.text-input.error {
  border: 1px solid var(--color-danger-700);
}

.input-container input.text-input.xsmall.error:focus {
  width: calc(100% - 14px);
  padding: 3px 7px;
  outline: none !important;
  border: 1px solid var(--color-danger-700);
}

.input-container input.text-input.base.error:focus {
  width: calc(100% - 14px);
  padding: 6px 6px;
  outline: none !important;
  border: 2px solid var(--color-danger-700);
}

.input-container .chip {
  width: 50px;
  height: 18px;
  background-color: var(--color-danger-100);
  border-radius: 12px;
  position: absolute;
  right: 8px;
  top: 5px;
  z-index: 10;
}

.input-container .chip p {
  margin: 0px;
  padding: 0px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  text-align: center;
  color: var(--color-danger-700);
}

.input-container .content {
  position: relative;
  width: 100%;
}

.input-container .content .item-container {
  width: 100%;
  max-height: 212px;
  position: absolute;
  top: 34px;
  left: 0px;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  z-index: 11;
}

.input-container .content .item-container.base {
  top: 47px;
}

.input-container.with-label .content .item-container.base {
  top: 71px;
}

.input-container .content .item-container.has-add-custom-button {
  padding-bottom: 32px;
}

.input-container .content .item-container .matched-items {
  width: 100%;
  max-height: 160px;
  background-color: var(--color-white);
  border-radius: 6px;
  border: 1px solid var(--color-stroke-300);
  z-index: 11;
  overflow: auto;
}

.input-container .content .item-container.has-add-custom-button .matched-items {
  border-radius: 6px 6px 0px 0px;
  border: none;
  border-top: 1px solid var(--color-stroke-300);
}

.input-container .content .matched-items .item {
  width: calc(100% - 32px);
  padding: 8px 16px;
  background: var(--color-white);
  float: left;
  position: relative;
}

.input-container .content .matched-items .item:hover {
  background: var(--color-interaction-primary-100);
  cursor: pointer;
}

.input-container .content .matched-items .item p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--color-text-icon-primary);
  float: left;
}

.input-container .content .item-container .button-container {
  width: calc(100% - 32px);
  height: 31px;
  padding: 0px 16px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: var(--color-neutral-100);
  border-top: 1px solid var(--color-stroke-300);
  cursor: pointer;
}

.input-container .content .item-container .button-container .icon {
  width: 8px;
  height: 8px;
  padding: 12px 0px;
  margin-right: 6px;
  float: left;
}

.input-container .content .item-container .button-container .icon.plus {
  background: url("../../../assets/icons/plus/plus-interaction-primary-700.svg") center center no-repeat;
  background-size: contain;
}

.input-container .content .item-container .button-container .text p {
  margin: 0px;
  padding: 10px 0px 9px 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: var(--color-interaction-primary-700);
  float: left;
}