.table-container {
  float: left;
  border: 1px solid var(--color-gray-200);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}

.table-container .content {
  width: 100%;
  float: left;
  position: relative;
}