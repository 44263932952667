.tab-container { 
  width: 100%;
  float: left;
}

.table-container { 
  width: 100%;
  float: left;
  margin-top: 16px;
}

.no-enagements-div {
  width: calc(100% - 2px);
  height: 348px;
  border: 1px solid var(--color-stroke-400);
  border-radius: 8px;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no-enagements-div .icon-container {
  width: 44px;
  height: 44px;
  border-radius: 4px;
  background-color: var(--color-neutral-300);
}

.no-enagements-div .icon-container .icon {
  width: 18px;
  height: 14px;
  margin: 15px 13px;
  background: url("../../../../assets/icons/list.svg") center center no-repeat;
  background-size: cover;
}

.no-enagements-div .text-container {
  width: 100%;
  margin: 16px 0px;
}

.no-enagements-div .text-container h4 {
  margin: 0px 0px 6px 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: center;
  color: var(--color-black);
}

.no-enagements-div .text-container p {
  margin: 6px 0px 0px 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--color-black);
}

.no-enagements-div .button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.proxy-advisor-engagement-list-buttons {
  float: right;
  margin-top: -49px;
}

.proxy-advisor-engagement-list-buttons .right-buttons {
  float: right;
}