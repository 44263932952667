.meeting-note-container {
  width: 100%;
  float: left;
}

.meeting-note-container .header {
  width: 100%;
  float: left;
}

.meeting-note-container .header .form-title {
  width: 100%;
  float: left;
  margin-bottom: 24px;
}

.meeting-note-container .header .form-title h2 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -1px;
}

.meeting-note-container .header .back-button-container { 
  width: 100%;
  float: left;
  margin-bottom: 24px;
}

.meeting-note-container .header .back-button-container .back-button {
  float: left;
  cursor: pointer;
}

.meeting-note-container .header .back-button-container .back-button .back-icon { 
  width: 14px;
  height: 8px;
  background: url("../../../assets/icons/arrow-left/arrow-left-text-icon-secondary.svg") center center no-repeat;
  background-size: cover;
  margin: 4px 8px 4px 0px;
  float: left;
}

.meeting-note-container .header .back-button-container .back-button h4 { 
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: var(--color-text-icon-secondary);
  float: left;
}

.meeting-note-container .header .meeting-note-title-container {
  width: 784px;
  float: left;
}

.meeting-note-container .header .title {
  float: left;
  margin-right: 16px;
}

.meeting-note-container .header .title h2 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -1px;
  color: var(--color-black);
}

.meeting-note-container .header .type-chip {
  float: left;
  margin: 8px 0px;
  padding: 4px 8px;
  background-color: var(--color-neutral-300);
  border-radius: 4px;
}

.meeting-note-container .header .type-chip .label p {
  padding: 0px;
  margin: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: var(--color-text-icon-primary);
}

.meeting-note-container .header .button-container {
  float: right;
  margin: 5px 0px;
}

.meeting-note-container .header .button-container div {
  margin-right: 8px;
}

.meeting-note-container .header .button-container div:last-child {
  margin-right: 0px;
}

.meeting-note-container .body {
  width: 100%;
  float: left;
  margin-top: 24px;
}

.meeting-note-container .body .separator {
  width: 100%;
  float: left;
  border-top: 1px solid var(--color-stroke-300);
  margin-bottom: 16px;
}

.meeting-note-container .body .row {
  width: 100%;
  float: left;
  margin-bottom: 16px;
}

.meeting-note-container .body .row .half-col {
  width: calc(50% - 8px);
  float: left;
}

.meeting-note-container .body .row .half-col:first-child {
  margin-right: 8px;
}

.meeting-note-container .body .row .half-col:last-child {
  margin-left: 8px;
}

.meeting-note-container .body .meeting-note-content-container {
  width: 784px;
  float: left;
  margin-right: 16px;
}

.meeting-note-container .body .meeting-note-details-container {
  width: 368px;
  float: left;
  margin: 24px 0px 0px 16px;
}