.left-fixed {
  position: absolute;
  left: 0px;
  z-index: 2;
}

.left-fixed .header {
  width: 100%;
  float: left;
}

.left-fixed .body {
  width: 100%;
  float: left;
}