.contact-details-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 100;
}

.contact-details-container .overlay {
  width: 100%;
  height: 100%;
  background-color: #0A0D14;
  opacity: 0.15;
  z-index: 100;
}

.contact-details-container .contact-details-form { 
  width: 476px;
  height: 100%;
  position: fixed;
  top: 0px;
  right: 0px;
  background-color: var(--color-white);
}

.contact-details-container .contact-details-form .header { 
  width: calc(100% - 48px);
  padding: 24px;
  border-bottom: 1px solid var(--color-stroke-300);
  background-color: var(--color-neutral-100);
  float: left;
}

.contact-details-container .contact-details-form .header .title {
  float: left;
}

.contact-details-container .contact-details-form .header .title h1 {
  padding: 0px;
  margin: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -1px;
  color: var(--color-text-icon-primary);
}

.contact-details-container .contact-details-form .header .close-button {
  width: 24px;
  height: 24px;
  float: right;
}

.contact-details-container .contact-details-form .header .close-button .close-icon {
  width: 14px;
  height: 14px;
  margin: 5px 0px 5px 10px;
  background: url("../../assets/icons/cross.svg") center center no-repeat;
  background-size: cover;
  cursor: pointer;
}

.contact-details-container .contact-details-form .body {
  width: calc(100% - 104px);
  height: calc(100% - 194px);
  padding: 24px 52px;
  float: left;
  overflow: auto;
}

.contact-details-container .contact-details-form .body .row {
  width: 100%;
  float: left;
  margin-bottom: 24px;
}

.contact-details-container .contact-details-form .body .row:last-child {
  margin-bottom: 0px;
}

.contact-details-container .contact-details-form .body .contact-photo-upload-container {
  width: 100%;
  float: left;
}

.contact-details-container .contact-details-form .body .contact-photo-upload-container .photo-container {
  width: 100%;
  float: left;
}

.contact-details-container .contact-details-form .body .contact-photo-upload-container .button-container {
  width: 100%;
  float: left;
}

.contact-details-container .contact-details-form .body .contact-photo-upload-container .contact-photo {
  width: 88px;
  height: 88px;
  border: 1px solid var(--color-neutral-300);
  border-radius: 50%;
  margin: auto;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.contact-details-container .contact-details-form .body .contact-photo-upload-container .contact-photo-upload-button {
  width: 100%;
  margin: 8px 0px 0px 0px;
  float: left;
}

.contact-details-container .contact-details-form .body .contact-photo-upload-container .contact-photo-upload-button input {
  display: none;
}

.contact-details-container .contact-details-form .body .contact-photo-upload-container .contact-photo-upload-button h4 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: var(--color-interaction-primary-700);
  cursor: pointer;
  text-align: center;
}

.contact-details-container .contact-details-form .footer { 
  width: calc(100% - 48px);
  padding: 16px 24px;
  border-bottom: 1px solid var(--color-stroke-300);
  background-color: var(--color-neutral-100);
  float: left;
}

.contact-details-container .contact-details-form .footer .left-buttons-container {
  float: left;
}

.contact-details-container .contact-details-form .footer .right-buttons-container {
  float: right;
}

.contact-details-container .contact-details-form .footer .right-buttons-container div {
  margin-right: 8px;
}

.contact-details-container .contact-details-form .footer .right-buttons-container div:last-child {
  margin-right: 0px;
}

.contact-details-container .contact-details-form .footer .right-buttons-container div.view-only-filler {
  height: 40px;
}

.contact-details-container .contact-details-form .body .new-contact-photo-upload-container {
  width: 100%;
  margin-bottom: 0px;
  float: left;
}

.contact-details-container .contact-details-form .body .new-contact-photo-upload-container .new-photo-container {
  width: 100%;
  float: left;
}

.contact-details-container .contact-details-form .body .new-contact-photo-upload-container .new-contact-photo {
  width: 86px;
  height: 86px;
  border-radius: 50%;
  margin: auto;
  border: 2px dashed var(--color-interaction-primary-300);
}

.contact-details-container .contact-details-form .body .new-contact-photo-upload-container .new-contact-photo .photo {
  width: 86px;
  height: 86px;
  border-radius: 50%;
  margin: auto;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: -1;
  opacity: 0.15;
}

.contact-details-container .contact-details-form .body .new-contact-photo-upload-container .new-contact-photo label .plus-icon {
  width: 21px;
  height: 21px;
  background: url("../../assets/icons/plus/plus-interaction-primary-700.svg") center center no-repeat;
  background-size: cover;
  margin: 32.5px auto 32.5px auto;
  cursor: pointer;
}

.contact-details-container .contact-details-form .body .new-contact-photo-upload-container .new-contact-photo input {
  display: none;
}

.contact-details-container .contact-details-form .body .new-contact-photo-upload-container h4 {
  width: 100%;
  float: left;
  margin: 8px 0px 0px 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: var(--color-text-icon-secondary);
  text-align: center;
}