.client-details-user-list {
  width: 100%;
  float: left;
}

.client-details-user-list .user-list-table-top-container {
  width: 100%;
  float: left;
  margin-bottom: 24px;
}

.client-details-user-list .user-list-table-top-container .title {
  float: left;
}

.client-details-user-list .user-list-table-top-container .title h1 {
  margin: 2px 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -1px;
  color: var(--color-black);
  float: left;
}

.client-details-user-list .user-list-table-top-container .action-buttons-container .right-buttons {
  float: right;
  margin-top: -6px;
}

.client-details-user-list .user-list-table-top-container .action-buttons-container .right-buttons div {
  margin-right: 8px;
}

.client-details-user-list .user-list-table-top-container .action-buttons-container .right-buttons div:last-child {
  margin-right: 0px;
}