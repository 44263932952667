.glossary-section {
  width: 100%;
  float: left;
}

.glossary-section .glossary-navigation {
  width: 100%;
  float: left;
  display: flex;
  flex-direction: column;
}

.glossary-section .letter-picker {
  max-width: 1220px;
  margin: 0px auto 24px auto;
}

.glossary-section .letter-picker .letter-item {
  width: 16px;
  float: left;
  margin-right: 32px;
}

.glossary-section .letter-picker .letter-item:last-child {
  margin-right: 0px;
}

.glossary-section .letter-picker .letter-item.active {
  cursor: pointer;
}

.glossary-section .letter-picker .letter-item h3 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -1px;
}

.glossary-section .letter-picker .letter-item.active h3 {
  color: var(--color-interaction-primary-700);
}

.glossary-section .letter-picker .letter-item.passive h3 {
  color: var(--color-text-icon-primary);
}

.glossary-section .glossary-introduction {
  max-width: 1220px;
  margin: auto;
}

.glossary-section .glossary-introduction p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-text-icon-primary);
  text-align: center;
}

.glossary-section .dictionary {
  width: 100%;
  float: left;
  margin-top: 64px;
}

.glossary-section .dictionary .letter-item {
  width: 100%;
  float: left;
}

.glossary-section .dictionary .letter-item h2 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -1px;
  color: var(--color-interaction-primary-700);
}

.glossary-section .dictionary .letter-item .word-list {
  width: 100%;
  float: left;
  margin: 24px 0px;
  border-bottom: 1px solid var(--color-stroke-300);
}

.glossary-section .dictionary .letter-item .word-list .word-item {
  width: 100%;
  float: left;
  margin-bottom: 24px;
}

.glossary-section .dictionary .letter-item .word-list .word-item .word {
  width: 100%;
  float: left;
  margin-bottom: 8px;
}

.glossary-section .dictionary .letter-item .word-list .word-item .word h4 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 2%;
  color: var(--color-text-icon-primary);
}

.glossary-section .dictionary .letter-item .word-list .word-item .definition {
  width: 100%;
  float: left;
}

.glossary-section .dictionary .letter-item .word-list .word-item .definition ul {
  margin: 8px 0px 0px 0px;
}

.glossary-section .dictionary .letter-item .word-list .word-item .definition p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--color-text-icon-primary);
}