.page {
  width: 100%;
  height: 100%;
  float: left;
}

.page .container.with-account-manager-warning {
  margin-top: 65px;
}

.page .container.wide {
  width: calc(100% - 120px);
  padding: 48px 32px;
  float: left;
  margin-left: 56px;
}

.page .container.narrow {
  width: calc(100% - 320px);
  padding: 48px 32px;
  float: left;
  margin-left: 256px;
}

.page-header {
  width: 100%;
  float: left;
}

.page-header .section .row {
  max-width: 100%;
  width: 100%;
  float: left;
  margin-bottom: 8px;
}

.page-header h1 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: -1px;
  float: left;
}

.page-header .section .row .title {
  float: left;
  margin-bottom: 24px;
}

.page-body {
  width: 100%;
  float: left;
}

.docusearch-query-container {
  width: 100%;
  float: left;
  margin-bottom: 32px;
}

.docusearch-query-container .row {
  max-width: 100%;
  width: 100%;
  float: left;
  margin-bottom: 8px;
}

.docusearch-query-container .investor-filter {
  float: left;
}

.docusearch-query-container .search-keyword {
  float: left;
  margin-left: 8px;
}

.docusearch-query-container .search-button {
  float: left;
  margin-left: 8px;
  margin-top: 24px;
}

.docusearch-query-container .added-filter-investors-container {
  width: 100%;
  float: left;
  margin-top: 8px;
}

.docusearch-query-container .added-filter-investors-container .added-filter-investor {
  padding: 11px;
  border: 1px solid var(--color-stroke-300);
  background-color: var(--color-interaction-primary-100);
  border-radius: 4px;
  float: left;
  margin: 8px 8px 0px 0px;
}

.docusearch-query-container .added-filter-investors-container .added-filter-investor .added-filter-investor-text {
  width: calc(100% - 16px);
  float: left;
}

.docusearch-query-container .added-filter-investors-container .added-filter-investor .added-filter-investor-text h3 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: var(--color-text-icon-primary);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.docusearch-query-container .added-filter-investors-container .added-filter-investor .remove-button {
  width: 8px;
  height: 8px;
  background: url("../../../assets/icons/cross.svg") center center no-repeat;
  background-size: cover;
  float: left;
  margin: 5px 0px 5px 8px;
  cursor: pointer;
}

.docusearch-query-container .added-filter-investors-container .note {
  width: 100%;
  float: left;
}

.docusearch-query-container .added-filter-investors-container .note p {
  padding: 0px;
  margin: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-text-icon-primary);
}

.docusearch-results-container {
  width: 100%;
  float: left;
}

.docusearch-results-container .query-title {
  width: 100%;
  float: left;
  margin-bottom: 16px;
}

.docusearch-results-container .query-title h3 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -1px;
  color: var(--color-text-icon-primary);
}

.docusearch-results-container .report-list {
  width: 100%;
  float: left;
}

.docusearch-results-container .report-list .report-item {
  width: 100%;
  float: left;
  margin-bottom: 12px;
}

.docusearch-results-container .report-list .report-item .header {
  width: 100%;
  float: left;
  border: 1px solid var(--color-neutral-300);
  background-color: var(--color-white);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.docusearch-results-container .report-list .report-item .header .title {
  width: calc(100% - 160px);
  padding: 16px 32px 16px 16px;
  float: left;
}

.docusearch-results-container .report-list .report-item .header .title .report-title {
  width: 100%;
  float: left;
  margin-bottom: 4px;
}

.docusearch-results-container .report-list .report-item .header .title .report-title a {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.28px;
  color: var(--color-interaction-primary-700);
  float: left;
  text-decoration: none;
}

.docusearch-results-container .report-list .report-item .header .title .report-title h4 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.28px;
  color: var(--color-interaction-primary-700);
  float: left;
}

.docusearch-results-container .report-list .report-item .header .title .report-title h4.dark {
  color: var(--color-text-icon-primary);
}

.docusearch-results-container .report-list .report-item .header .title .report-title h4.clickable {
  cursor: pointer;
}

.docusearch-results-container .report-list .report-item .header .title .report-title .skeleton-placeholder {
  margin: 1px 0px;
  height: 14px;
  float: left;
  background: var(--color-interaction-primary-100);
  background: linear-gradient(110deg, #F0F5FF 8%, #EBEAFE 18%, #F0F5FF 33%);
  background-size: 200% 100%;
  animation: 2.5s shine linear infinite;
}

.docusearch-results-container .report-list .report-item .header .title .report-title .download-icon { 
  width: 16px;
  height: 16px;
  float: left;
  margin: 0px 8px 0px 0px;
  cursor: pointer;
}

.docusearch-results-container .report-list .report-item .header .title .report-title .download-icon .download { 
  width: 16px;
  height: 16px;
  float: left;
  background: url("../../../assets/icons/download/download-interaction-primary-700.svg") center center no-repeat;
  background-size: cover;
}

.docusearch-results-container .report-list .report-item .header .title .report-title .separator {
  float: left;
  margin: 0px 8px;
}

.docusearch-results-container .report-list .report-item .header .title h4 .highlighted {
  background-color: var(--color-highlight-orange);
}

.docusearch-results-container .report-list .report-item .header .title .report-details {
  width: 100%;
  float: left;
  margin-top: 4px;
  display: flex;
}

.docusearch-results-container .report-list .report-item .header .title .report-details .skeleton-placeholder {
  margin: 2px 0px;
  height: 12px;
  float: left;
  background: var(--color-interaction-primary-100);
  background: linear-gradient(110deg, #F0F5FF 8%, #EBEAFE 18%, #F0F5FF 33%);
  background-size: 200% 100%;
  animation: 2.5s shine linear infinite;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.docusearch-results-container .report-list .report-item .header .title .report-details .separator {
  float: left;
  margin: 0px 8px;
}

.docusearch-results-container .report-list .report-item .header .title .report-details h5 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: var(--color-interaction-primary-700);
  cursor: pointer;
}

.docusearch-results-container .report-list .report-item .header .icon-container {
  width: 104px;
  height: 24px;
  padding: 0px 16px 0px 0px;
  float: left;
}

.docusearch-results-container .report-list .report-item .header .icon-container .num-of-hits-icon { 
  width: 44px;
  height: 16px;
  float: right;
  margin: 4px 14px 4px 0px;
}

.docusearch-results-container .report-list .report-item .header .icon-container .num-of-hits-icon p {
  margin: 0px 4px 0px 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--color-text-icon-primary);
  float: right;
}

.docusearch-results-container .report-list .report-item .header .icon-container .num-of-hits-icon .num-of-hits { 
  width: 16px;
  height: 16px;
  float: right;
  background: url("../../../assets/icons/comment/comment-text-icon-secondary.svg") center center no-repeat;
  background-size: cover;
}

.docusearch-results-container .report-list .report-item .header .icon-container .drawer-icon { 
  width: 8px;
  height: 16px;
  float: right;
  margin: 4px 0px 4px 0px;
}

.docusearch-results-container .report-list .report-item .header .icon-container .drawer-icon .caret {
  width: 8px;
  height: 14px;
  margin: 1px;
  float: left;
  background: url("../../../assets/icons/caret-right/caret-right-text-icon-secondary.svg") center center no-repeat;
  background-size: cover;
  cursor: pointer;
}

.docusearch-results-container .report-list .report-item .body {
  width: calc(100% - 64px);
  float: left;
  border: 1px solid var(--color-neutral-300);
  border-top: none;
  background-color: var(--color-interaction-primary-100);
  padding: 24px 32px;
}

.docusearch-results-container .report-list .report-item .body .citation {
  width: calc(100% - 104px);
  padding: 0px;
  float: left;
}

.docusearch-results-container .report-list .report-item .body .citation p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--color-text-icon-secondary);
}

.docusearch-results-container .report-list .report-item .body .citation p .highlighted {
  background-color: var(--color-highlight-orange);
}

.docusearch-results-container .report-list .report-item .body .page-reference {
  width: 104px;
  height: 24px;
  padding: 0px;
  float: right;
  text-align: right;
}

.docusearch-results-container .report-list .report-item .body .page-reference p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.28px;
  color: var(--color-interaction-primary-700);
  cursor: pointer;
}

.docusearch-pagination-container {
  width: 100%;
  float: left;
}

.docusearch-pagination-container .pagination-items {
  float: right;
}

.docusearch-pagination-container .first-page-button {
  float: left;
  margin-right: 7px;
}

.docusearch-pagination-container .last-page-button {
  float: left;
  margin-left: 7px;
}

.docusearch-pagination-container .pagination-number {
  padding: 6px 7px;
  border: none;
  float: left;
  margin: 4px 1px;
  cursor: pointer;
}

.docusearch-pagination-container .pagination-number.selected {
  padding: 5px 6px;
  border: 1px solid var(--color-interaction-primary-700);
  border-radius: 4px;
  cursor: default;
}

.docusearch-pagination-container .pagination-number.three-dots {
  cursor: default;
}

.docusearch-pagination-container .pagination-number p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0.28px;
  text-align: center;
  color: var(--color-text-icon-secondary);
}

.docusearch-pagination-container .pagination-number.selected p {
  color: var(--color-text-icon-primary);
}











.no-results-div {
  width: calc(100% - 2px);
  height: 348px;
  border: 1px solid var(--color-stroke-400);
  border-radius: 8px;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no-results-div .icon-container {
  width: 44px;
  height: 44px;
  border-radius: 4px;
  background-color: var(--color-neutral-300);
}

.no-results-div .icon-container .icon {
  width: 18px;
  height: 14px;
  margin: 15px 13px;
  background: url("../../../assets/icons/list.svg") center center no-repeat;
  background-size: cover;
}

.no-results-div .text-container {
  width: 100%;
  margin: 16px 0px;
}

.no-results-div .text-container h4 {
  margin: 0px 0px 6px 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: center;
  color: var(--color-black);
}

.no-results-div .text-container p {
  margin: 6px 0px 0px 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--color-black);
}
