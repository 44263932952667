.page {
  width: 100%;
  height: 100%;
  float: left;
}

.page .container.wide {
  width: calc(100% - 120px);
  padding: 48px 32px;
  float: left;
  margin-left: 56px;
}

.page .container.narrow {
  width: calc(100% - 320px);
  padding: 48px 32px;
  float: left;
  margin-left: 256px;
}

.client-setup-container {
  width: 100%;
  float: left;
  position: relative;
}

.client-setup-container .client-setup-progress {
  margin-top: 16px;
}

.client-setup-form-title {
  width: 100%;
  float: left;
}

.client-setup-form-title h1 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: -1px;
  float: left;
}

.client-setup-progress {
  width: 100%;
  float: left;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}