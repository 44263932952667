#emailBodyCopyContainer {
  display: none;
}

.modal-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 100;
}

.modal-container .overlay {
  width: 100%;
  height: 100%;
  background-color: #0A0D14;
  opacity: 0.15;
  z-index: 100;
}

.modal-container .content {
  width: 1000px;
  padding: 32px 40px;
  background-color: var(--color-white);
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  position: absolute;
  /*top: 200px;*/
  top: 120px;
  left: calc(calc(100% - 1080px) / 2);
}

.modal-container .content .header {
  width: 100%;
  float: left;
}

.modal-container .content .header .title {
  float: left;
  margin-right: 12px;
}

.modal-container .content .header .title h3 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -1px;
  color: var(--color-text-icon-primary);
}

.modal-container .content .header .close-button {
  width: 32px;
  height: 32px;
  float: right;
}

.modal-container .content .header .close-button .close-icon {
  width: 14px;
  height: 14px;
  margin: 9px 0px 9px 18px;
  background: url("../../../assets/icons/cross.svg") center center no-repeat;
  background-size: cover;
  cursor: pointer;
}

.modal-container .content .body {
  width: 100%;
  float: left;
  margin-top: 32px;
}

.modal-container .content .body .left-container {
  width: calc(60% - 16px);
  height: 422px;
  float: left;
  margin-right: 16px;
  overflow: auto;
}

.modal-container .content .body .left-container .email-body {
  width: 100%;
  float: left;
  margin-top: 32px;
}

.modal-container .content .body .right-container {
  width: calc(40% - 16px);
  height: 422px;
  float: left;
  margin-left: 16px;
  overflow: auto;
}

.modal-container .content .body .right-container .email-to-container {
  width: 100%;
  float: left;
}

.modal-container .content .body .right-container .email-cc-container {
  width: 100%;
  float: left;
  margin-top: 32px;
}




.modal-container .content .body .right-container .added-email-contacts-container {
  width: 100%;
  float: left;
  margin-top: 8px;
}

.modal-container .content .body .right-container .added-email-contacts-container .added-email-contact {
  max-width: calc(100% - 24px);
  padding: 11px;
  border: 1px solid var(--color-stroke-300);
  background-color: var(--color-interaction-primary-100);
  border-radius: 4px;
  float: left;
  margin: 8px 8px 0px 0px;
}

.modal-container .content .body .right-container .added-email-contacts-container .added-email-contact .added-email-contact-text {
  width: calc(100% - 16px);
  float: left;
}

.modal-container .content .body .right-container .added-email-contacts-container .added-email-contact .added-email-contact-text.disabled {
  width: 100%;
  float: left;
}

.modal-container .content .body .right-container .added-email-contacts-container .added-email-contact .added-email-contact-text h3 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: var(--color-text-icon-primary);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.modal-container .content .body .right-container .added-email-contacts-container .added-email-contact .remove-button {
  width: 8px;
  height: 8px;
  background: url("../../../assets/icons/cross.svg") center center no-repeat;
  background-size: cover;
  float: left;
  margin: 5px 0px 5px 8px;
  cursor: pointer;
}

.modal-container .content .body .right-container .note {
  width: 100%;
  float: left;
}

.modal-container .content .body .right-container .note p {
  padding: 0px;
  margin: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-text-icon-primary);
}

.modal-container .content .footer {
  width: 100%;
  float: left;
  margin-top: 32px;
}

.modal-container .content .footer .modal-button{
  float: right;
  margin-left: 8px;
}

.modal-container .content .footer .modal-button:last-child{
  margin-left: 0px;
}