.modal-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 100;
}

.modal-container .overlay {
  width: 100%;
  height: 100%;
  background-color: #0A0D14;
  opacity: 0.15;
  z-index: 100;
}

.modal-container .content {
  width: 412px;
  padding: 24px;
  background-color: var(--color-white);
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  position: absolute;
  top: 200px;
  left: calc(calc(100% - 460px) / 2);
}

.modal-container .content .header {
  width: 100%;
  float: left;
}

.modal-container .content .header .icon {
  width: 32px;
  height: 32px;
  float: left;
}

.modal-container .content .header .icon.info{
  background: url("../../../assets/icons/information/information-notice.svg") center center no-repeat;
  background-size: cover;
}

.modal-container .content .header .icon.warning{
  background: url("../../../assets/icons/information/information-warning.svg") center center no-repeat;
  background-size: cover;
}

.modal-container .content .header .icon.critical{
  background: url("../../../assets/icons/information/information-critical.svg") center center no-repeat;
  background-size: cover;
}

.modal-container .content .header .icon.success{
  background: url("../../../assets/icons/information/information-success.svg") center center no-repeat;
  background-size: cover;
}

.modal-container .content .header .title {
  width: 325px;
  float: left;
  margin: 0px 8px;
}

.modal-container .content .header .title h1 {
  padding: 4px 0px;
  margin: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -1px;
  color: var(--color-text-icon-primary);
}

.modal-container .content .header .close-button {
  width: 32px;
  height: 32px;
  float: right;
}

.modal-container .content .header .close-button .close-icon {
  width: 14px;
  height: 14px;
  margin: 9px 0px 9px 18px;
  background: url("../../../assets/icons/cross.svg") center center no-repeat;
  background-size: cover;
  cursor: pointer;
}

.modal-container .content .body {
  width: calc(100% - 40px);
  min-height: 120px;
  float: left;
  margin: 8px 0px 24px 40px;
}

.modal-container .content .body p {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-black);
}

.modal-container .content .footer {
  width: 100%;
  float: left;
}

.modal-container .content .footer .modal-button{
  float: right;
  margin-left: 8px;
}

.modal-container .content .footer .modal-button:last-child{
  margin-left: 0px;
}