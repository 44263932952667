.page {
  width: 100%;
  height: 100%;
  float: left;
}

.page .container.with-account-manager-warning {
  margin-top: 65px;
}

.page .container.wide {
  width: calc(100% - 120px);
  padding: 48px 32px;
  float: left;
  margin-left: 56px;
}

.page .container.narrow {
  width: calc(100% - 320px);
  padding: 48px 32px;
  float: left;
  margin-left: 256px;
}

.page-header {
  width: 100%;
  float: left;
}

.page-header .section .row {
  max-width: 100%;
  width: 100%;
  float: left;
  margin-bottom: 24px;
}

.page-header h1 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: -1px;
  float: left;
}

.page-header .section .row .title {
  float: left;
}

.page-header .section .row .search-box {
  float: right;
  margin: 6px 0px;
}

.page-body {
  width: 100%;
  float: left;
}

.investor-details-container {
  width: 100%;
  float: left;
  position: relative;
}

.investor-details-container .investor-details-header {
  width: 100%;
  margin: 16px 0px 16px 0px;
  float: left;
}

.investor-details-container .investor-details-header .logo {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 1px solid var(--color-neutral-300);
  float: left;
  margin-right: 16px;
}

.investor-details-container .investor-details-header .logo .missing-logo {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  border: 2px dashed var(--color-interaction-primary-300);
  float: left;
}

.investor-details-container .investor-details-header .title-container {
  width: calc(100% - 88px);
  float: left;
}

.investor-details-container .investor-details-header .title {
  float: left;
  margin-right: 16px;
}

.investor-details-container .investor-details-header .title h1 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -1px;
  float: left;
}

.investor-details-container .investor-details-header .isc-percentage {
  float: left;
  background-color: var(--color-interaction-primary-100);
  border-radius: 2px;
  padding: 4px;
  margin-right: 16px;
}

.investor-details-container .investor-details-header .isc-percentage h4 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: var(--color-text-icon-primary);
}

.investor-details-container .investor-details-header .custom-investor-ribbon {
  width: 135px;
  height: 18px;
  background-color: var(--color-danger-100);
  border: 1px solid var(--color-danger-200);
  border-radius: 100px;
  float: left;
  padding: 2px 0px;
}

.investor-details-container .investor-details-header .custom-investor-ribbon p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: center;
  color: var(--color-danger-500);
}

.investor-details-container .investor-details-header .tab-container {
  width: calc(100% - 88px);
  float: left;
  margin-top: 7px;
}

.investor-details-container .investor-details-body {
  width: 100%;
  float: left;
  margin: 16px 0px 16px 0px;
}