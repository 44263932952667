.dropdown-container {
  position: relative;
}

.dropdown-container label.dropdown-label {
  width: 100%;
  padding: 0px 0px 8px 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #1D2433;
  float: left;
}

.dropdown-container .dropdown-header {
  background: var(--color-white);
  border: 1px solid var(--color-stroke-300);
  float: left;
  position: relative;
  cursor: pointer;
}

.dropdown-container .dropdown-header.view-only {
  cursor: not-allowed !important;
}

.dropdown-container .dropdown-header.success {
  background-color: var(--color-success-100);
  border-color: var(--color-success-100);
}

.dropdown-container .dropdown-header.warning {
  background-color: var(--color-warning-100);
  border-color: var(--color-warning-100);
}

.dropdown-container .dropdown-header.danger {
  background-color: var(--color-danger-100);
  border-color: var(--color-danger-100);
}

.dropdown-container .dropdown-header.unknown {
  background-color: var(--color-neutral-200);
  border-color: var(--color-neutral-200);
}

.dropdown-container .dropdown-header.info {
  background-color: var(--color-interaction-primary-200);
  border-color: var(--color-interaction-primary-200);
}

.dropdown-container.xsmall .dropdown-header {
  width: calc(100% - 16px);
  padding: 3px 7px;
  border-radius: 4px;
}

.dropdown-container.small .dropdown-header {
  width: calc(100% - 16px);
  padding: 5px 7px;
  border-radius: 4px;
}

.dropdown-container.base .dropdown-header {
  width: calc(100% - 16px);
  padding: 7px 7px;
  border-radius: 8px;
}

.dropdown-container .dropdown-header.error {
  border: 1px solid var(--color-danger-700);
}

.dropdown-container.disabled .dropdown-header {
  background: var(--color-disabled);
  cursor: default;
}

.dropdown-container .dropdown-header .chip {
  width: 8px;
  height: 8px;
  float: left;
  margin: 6px 8px 6px 0px;
  border-radius: 50%;
}

.dropdown-container.base .dropdown-header .chip {
  margin: 8px 8px 8px 0px;
}

.dropdown-container .dropdown-header .chip.success {
  background-color: var(--color-success-500);
}

.dropdown-container .dropdown-header .chip.warning {
  background-color: var(--color-warning-700);
}

.dropdown-container .dropdown-header .chip.danger {
  background-color: var(--color-danger-700);
}

.dropdown-container .dropdown-header .chip.unknown {
  background-color: var(--color-text-icon-secondary);
}

.dropdown-container .dropdown-header .chip.info {
  background-color: var(--color-interaction-primary-700);
}

.dropdown-container .dropdown-header .text p {
  width: calc(100% - 20px);
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  float: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.dropdown-container .dropdown-header .text.has-chip p {
  width: calc(100% - 36px);
}

.dropdown-container.xsmall .dropdown-header .text p {
  font-size: 14px;
  line-height: 20px;
}

.dropdown-container.small .dropdown-header .text p {
  font-size: 14px;
  line-height: 20px;
}

.dropdown-container.xsmall .dropdown-header.success .text p {
  font-weight: 600;
  letter-spacing: 0.02em;
}

.dropdown-container.small .dropdown-header.success .text p {
  font-weight: 600;
  letter-spacing: 0.02em;
}

.dropdown-container.xsmall .dropdown-header.warning .text p {
  font-weight: 600;
  letter-spacing: 0.02em;
}

.dropdown-container.small .dropdown-header.warning .text p {
  font-weight: 600;
  letter-spacing: 0.02em;
}

.dropdown-container.xsmall .dropdown-header.danger .text p {
  font-weight: 600;
  letter-spacing: 0.02em;
}

.dropdown-container.small .dropdown-header.danger .text p {
  font-weight: 600;
  letter-spacing: 0.02em;
}

.dropdown-container.xsmall .dropdown-header.unknown .text p {
  font-weight: 600;
  letter-spacing: 0.02em;
}

.dropdown-container.small .dropdown-header.unknown .text p {
  font-weight: 600;
  letter-spacing: 0.02em;
}

.dropdown-container.xsmall .dropdown-header.info .text p {
  font-weight: 600;
  letter-spacing: 0.02em;
}

.dropdown-container.small .dropdown-header.info .text p {
  font-weight: 600;
  letter-spacing: 0.02em;
}

.dropdown-container.base .dropdown-header .text p {
  font-size: 16px;
  line-height: 24px;
}

.dropdown-container .dropdown-header .text p.placeholder {
  color: var(--color-text-icon-secondary);
}

.dropdown-container .dropdown-header .text p.value {
  color: var(--color-text-icon-primary);
}

.dropdown-container .dropdown-header.success .text p.value {
  color: var(--color-success-500);
}

.dropdown-container .dropdown-header.warning .text p.value {
  color: var(--color-warning-700);
}

.dropdown-container .dropdown-header.danger .text p.value {
  color: var(--color-danger-700);
}

.dropdown-container .dropdown-header.unknown .text p.value {
  color: var(--color-text-icon-secondary);
}

.dropdown-container .dropdown-header.info .text p.value {
  color: var(--color-interaction-primary-700);
}

.dropdown-container .dropdown-header .icon {
  width: 11px;
  height: 6px;
  background: url("../../../assets/icons/arrow-down.svg") center center no-repeat;
  background-size: cover;
  position: absolute;
  right: 8px;
  cursor: pointer;
}

.dropdown-container .dropdown-header.view-only .icon {
  cursor: not-allowed !important;
}

.dropdown-container .dropdown-header.success .icon {
  background: url("../../../assets/icons/caret-down/caret-down-success-500.svg") center center no-repeat;
}

.dropdown-container .dropdown-header.warning .icon {
  background: url("../../../assets/icons/caret-down/caret-down-warning-700.svg") center center no-repeat;
}

.dropdown-container .dropdown-header.danger .icon {
  background: url("../../../assets/icons/caret-down/caret-down-danger-700.svg") center center no-repeat;
}

.dropdown-container .dropdown-header.unknown .icon {
  background: url("../../../assets/icons/caret-down/caret-down-text-icon-secondary.svg") center center no-repeat;
}

.dropdown-container .dropdown-header.info .icon {
  background: url("../../../assets/icons/caret-down/caret-down-interaction-primary-700.svg") center center no-repeat;
}

.dropdown-container.base .dropdown-header .icon {
  top: 16px;
}

.dropdown-container.xsmall .dropdown-header .icon {
  top: 10px;
}

.dropdown-container.small .dropdown-header .icon {
  top: 12px;
}


.dropdown-container .dropdown-text-header {
  width: 100%;
  float: left;
  position: relative;
  cursor: pointer;
}

.dropdown-container .dropdown-text-header input.text-input {
  background: var(--color-white);
  border: 1px solid var(--color-stroke-300);
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  color: var(--color-text-icon-primary);
  float: left;
  position: relative;
  cursor: pointer;
}

.dropdown-container .dropdown-text-header input.text-input.xsmall {
  width: calc(100% - 16px);
  padding: 3px 7px 3px 7px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 4px;
}

.dropdown-container .dropdown-text-header input.text-input.xsmall.custom {
  width: calc(100% - 67px);
  padding: 3px 58px 3px 7px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 4px;
}

.dropdown-container .dropdown-text-header input.text-input.base {
  width: calc(100% - 16px);
  padding: 7px 7px;
  font-size: 16px;
  line-height: 24px;
  border-radius: 8px;
}

.dropdown-container .dropdown-text-header input.text-input.xsmall:focus {
  width: calc(100% - 16px);
  padding: 3px 7px 3px 7px;
  outline: none !important;
  border: 1px solid var(--color-info);
  cursor: default;
}

.dropdown-container .dropdown-text-header input.text-input.xsmall.custom:focus {
  width: calc(100% - 67px);
  padding: 3px 58px 3px 7px;
  outline: none !important;
  border: 1px solid var(--color-info);
  cursor: default;
}

.dropdown-container .dropdown-text-header input.text-input.base:focus {
  width: calc(100% - 14px);
  padding: 6px 6px;
  outline: none !important;
  border: 2px solid var(--color-info);
  cursor: default;
}

.dropdown-container .dropdown-text-header input.text-input::placeholder {
  color: var(--color-text-icon-secondary);
}

.dropdown-container .dropdown-text-header input.text-input:disabled {
  background: var(--color-disabled);
  cursor: default;
}

.dropdown-container .dropdown-text-header input.text-input.error {
  border: 1px solid var(--color-danger-700);
}

.dropdown-container .dropdown-text-header input.text-input.xsmall.error:focus {
  width: calc(100% - 14px);
  padding: 3px 7px;
  outline: none !important;
  border: 1px solid var(--color-danger-700);
  cursor: default;
}

.dropdown-container .dropdown-text-header input.text-input.base.error:focus {
  width: calc(100% - 14px);
  padding: 6px 6px;
  outline: none !important;
  border: 2px solid var(--color-danger-700);
  cursor: default;
}

.dropdown-container .dropdown-text-header .icon {
  width: 11px;
  height: 6px;
  background: url("../../../assets/icons/arrow-down.svg") center center no-repeat;
  background-size: cover;
  position: absolute;
  right: 8px;
  cursor: pointer;
}

.dropdown-container.base .dropdown-text-header .icon {
  top: 16px;
}

.dropdown-container.xsmall .dropdown-text-header .icon {
  top: 10px;
}

.dropdown-container.small .dropdown-text-header .icon {
  top: 12px;
}


.dropdown-container .dropdown-list {
  width: calc(100% - 2px);
  max-height: 240px;
  padding: 8px 0px;
  background: var(--color-white);
  border: 1px solid var(--color-stroke-300);
  border-radius: 6px;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.16);
  position: absolute;
  left: 0px;
  overflow: auto;
  z-index: 10;
}

.dropdown-container.xsmall .dropdown-list {
  top: 31px;
}

.dropdown-container.small .dropdown-list {
  top: 37px;
}

.dropdown-container.base .dropdown-list {
  top: 47px;
}

.dropdown-container.with-label .dropdown-list {
  top: 71px;
  left: 0px;
}

.dropdown-container .dropdown-list .list-label {
  width: calc(100% - 32px);
  padding: 4px 16px;
  background: var(--color-white);
  float: left;
}

.dropdown-container .dropdown-list .list-label p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #1D2433A6;
  float: left;
}