.breadcrumbs-container {
  float: left;
  margin-top: -32px;
}

.breadcrumbs-container .breadcrumb-item {
  float: left;
}

.breadcrumbs-container .breadcrumb-separator {
  float: left;
  margin: 0px 22px;
}

.breadcrumbs-container p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-text-icon-disabled);
}

.breadcrumbs-container a {
  color: var(--color-text-icon-disabled);
  text-decoration: none;
}