.cell.header {
  float: left;
  padding: 12px 0px 12px 24px;
}

.cell.header .text p {
  float: left;
  padding: 0px;
  margin: 0px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-gray-500);
}

.cell.header .sort-icon.arrow-down{
  float: left;
  width: 8px;
  height: 10px;
  background: url("../../../../assets/icons/arrow-down/arrow-down-gray-500.svg") no-repeat center center;
  background-size: contain;
  margin: 2px 0px 0px 4px;
  cursor: pointer;
}

.cell.header .sort-icon.arrow-up{
  float: left;
  width: 8px;
  height: 10px;
  background: url("../../../../assets/icons/arrow-up/arrow-up-gray-500.svg") no-repeat center center;
  background-size: contain;
  margin: 2px 0px 0px 4px;
  cursor: pointer;
}

.cell.body {
  float: left;
  padding: 12px 0px 12px 24px;
}

.cell.body .text p {
  float: left;
  padding: 0px;
  margin: 4px 0px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-gray-500);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cell.body.default .text p {
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-gray-500);
}

.cell.body.default .text .skeleton {
  margin: 8px 0px;
  height: 12px;
  background-color: var(--color-interaction-primary-100);
  float: left;
}

.cell.body.link-button {
  position: relative;
}

.cell.body.link-button .pin {
  float: left;
  position: absolute;
}

.cell.body.link-button:hover .pin.star {
  width: 12px;
  height: 12px;
  background: url("../../../../assets/icons/star/star-empty.svg");
  background-size: cover;
  left: 12px;
  top: 20px;
  cursor: pointer;
}

.cell.body.link-button .pin.star.selected {
  width: 12px;
  height: 12px;
  background: url("../../../../assets/icons/star/star-filled.svg");
  background-size: cover;
  left: 12px;
  top: 20px;
  cursor: pointer;
}

.cell.body.link-button .photo {
  float: left;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--color-white);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 4px 8px 4px 0px
}

.cell.body.link-button .text {
  max-width: 100%;
  float: left;
}

.cell.body.link-button .text.has-custom-chip {
  max-width: calc(100% - 54px);
  float: left;
}

.cell.body.link-button .text.has-photo {
  max-width: calc(100% - 28px);
  float: left;
}

.cell.body.link-button .text.has-custom-chip.has-photo {
  max-width: calc(100% - 78px);
  float: left;
}

.cell.body.link-button .text p {
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-interaction-primary-700);
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  float: left;
}

.cell.body.link-button .text a {
  font-weight: 500;
  font-size: 14px;
  color: var(--color-interaction-primary-700);
  text-decoration: none;
}

.cell.body.link-button .text .skeleton {
  margin: 8px 0px;
  height: 12px;
  background-color: var(--color-interaction-primary-100);
  float: left;
}

.cell.body.link-button .chip {
  width: 50px;
  height: 18px;
  background-color: var(--color-danger-100);
  border-radius: 12px;
  float: left;
  margin: 5px 0px 5px 4px;
}

.cell.body.link-button .chip p {
  margin: 0px;
  padding: 0px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  text-align: center;
  color: var(--color-danger-700);
}

.cell.body.text-with-chip .text {
  max-width: 100%;
  float: left;
}

.cell.body.text-with-chip .text.has-custom-chip {
  max-width: calc(100% - 54px);
  float: left;
}

.cell.body.text-with-chip .text p {
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-gray-500);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  float: left;
}

.cell.body.text-with-chip .chip {
  width: 50px;
  height: 18px;
  background-color: var(--color-danger-100);
  border-radius: 12px;
  float: left;
  margin: 5px 0px 5px 4px;
}

.cell.body.text-with-chip .chip p {
  margin: 0px;
  padding: 0px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  text-align: center;
  color: var(--color-danger-700);
}

.cell.body.traffic-light .text p {
  width: calc(100% - 16px);
}

.cell.body.traffic-light .color-code {
  width: 8px;
  height: 8px;
  float: left;
  margin: 10px 8px 10px 0px;
  border-radius: 50%;
}

.cell.body.traffic-light .color-code.success {
  background-color: var(--color-success-500);
}

.cell.body.traffic-light .color-code.warning {
  background-color: var(--color-warning-700);
}

.cell.body.traffic-light .color-code.danger {
  background-color: var(--color-danger-700);
}

.cell.body.traffic-light .color-code.unknown {
  background-color: var(--color-gray-700);
}

.cell.body.traffic-light .color-code.info {
  background-color: var(--color-interaction-primary-700);
}

.cell.body.button {
  float: left;
}

.cell.body.text-input {
  float: left;
}

.cell.body.number-input {
  float: left;
}

.cell.body.dropdown {
  float: left;
}

.cell.body.combobox {
  float: left;
}

.cell.body.icon-button {
  float: left;
}

.cell.body.icon-button .edit {
  width: 20px;
  height: 20px;
  margin: 0px auto;
  background: url("../../../../assets/icons/edit/edit-interaction-primary-700.svg") center center no-repeat;
  background-size: cover;
  cursor: pointer;
}

.cell.body.icon-button .eye {
  width: 18px;
  height: 13px;
  margin: 8px auto 7px auto;
  background: url("../../../../assets/icons/password-show.svg") center center no-repeat;
  background-size: cover;
  cursor: pointer;
}

.cell.body.icon-button .trash {
  width: 20px;
  height: 20px;
  margin: 0px auto;
  background: url("../../../../assets/icons/trash/trash-text-icon-secondary.svg") center center no-repeat;
  background-size: cover;
  cursor: pointer;
}

.cell.body .progress-value {
  float: left;
  padding: 6px 0px;
  margin-right: 8px;
}

.cell.body .progress-value p {
  float: left;
  padding: 0px;
  margin: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-gray-500);
}

.cell.body .progress-bar {
  float: left;
  padding: 12px 0px;
}

.cell.body .progress-bar .bar-item.passive {
  width: 20px;
  height: 2px;
  float: left;
  border: 1px solid var(--color-neutral-300);
  background-color: var(--color-neutral-300);
}

.cell.body .progress-bar .bar-item.active {
  width: 20px;
  height: 2px;
  float: left;
  border: 1px solid var(--color-success-500);
  background-color: var(--color-success-500);
}

.cell.body .progress-bar .bar-item:first-child {
  border-radius: 100px 0px 0px 100px;
}

.cell.body .progress-bar .bar-item:last-child {
  border-radius: 0px 100px 100px 0px;
}

.cell.body .progress-bar .bar-item.active::after {
  content: '';
  width: 1px;
  height: 4px;
  background-color: var(--color-neutral-300);
  margin-top: -1px;
  margin-right: -1px;
  float: right;
}

.cell.body .progress-bar .bar-item.active:last-child:after {
  content: '';
  width: 0px;
  height: 0px;
  float: right;
}



.cell.left-fixed {
  /* box-shadow: inset -10px 0 8px -8px rgb(5 5 5 / 6%); */
}

.cell.right-fixed {
  /* box-shadow: inset 10px 0 8px -8px rgb(5 5 5 / 6%); */
}