.radio-button-group {
  width: 100%;
  float: left;
}

.radio-button-group .item-separator {
  width: 0px;
  height: 17px;
  margin: 2px 8px 0px 8px;
  float: left;
  border-right: 1px solid var(--color-stroke-300);
}

.radio-button-group .item-separator:last-child {
  display: none;
}

.radio-button-group .radio-button {
  float: left;
}

.radio-button-group .radio-button input {
	position: absolute;
	opacity: 0;
}

.radio-button-group .radio-button input + label {
	position: relative;
	cursor: pointer;
	padding: 0px;
  margin: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-text-icon-secondary);
}

.radio-button-group .radio-button input + label:before {
	content: '';
	margin: -3px 8px 0px 0px;
  padding: 0px;
	display: inline-block;
	vertical-align: middle;
	width: 16px;
	height: 16px;
	background: url("../../../assets/other/radio-button-not-selected.svg") center center no-repeat;
	background-size: cover;
}

.radio-button-group .radio-button input:checked + label:before {
	width: 16px;
	height: 16px;
	background: url("../../../assets/other/radio-button-selected.svg") center center no-repeat;
	background-size: cover;
}

.radio-button-group .radio-button .icon { 
  width: 16px;
  height: 16px;
  float: right;
  margin: 2px 0px 0px 8px;
}

.radio-button-group .radio-button .icon .flag {
  width: 14px;
  height: 14px;
  margin: 1px;
  float: left;
  background: url("../../../assets/icons/flag/flag.svg") center center no-repeat;
  background-size: cover;
}