
.rich-text-editor-container {
  width: 800px;
  height: 302px;
  position: relative;
  float: left;
}

.rich-text-editor-label {
  width: 100%;
  padding: 0px 0px 8px 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #1D2433;
  float: left;
}

.rich-text-editor-container .quill .ql-container.ql-snow {
  height: 260px;
  background: var(--color-white);
  border: 1px solid var(--color-stroke-300);
  border-bottom: none;
  border-radius: 8px 8px 0px 0px;
}

.rich-text-editor-container .quill .ql-container.ql-snow .ql-editor {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-icon-primary);
}

.rich-text-editor-container .quill .ql-container.ql-snow .ql-editor.ql-blank::before {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-icon-secondary);
}

.rich-text-editor-container .quill .ql-toolbar.ql-snow {
  width: 100%;
  position: absolute;
  top: 260px;
  background: var(--color-neutral-200);
  border: 1px solid var(--color-stroke-300);
  border-radius: 0px 0px 8px 8px;
}

.rich-text-editor-container .quill .ql-toolbar.ql-snow button svg line{
  stroke: var(--color-text-icon-secondary);
}

.rich-text-editor-container .quill .ql-toolbar.ql-snow button svg .ql-stroke{
  stroke: var(--color-text-icon-secondary);
}

.rich-text-editor-container .quill .ql-toolbar.ql-snow button svg .ql-fill{
  fill: var(--color-text-icon-secondary);
}

.rich-text-editor-container .quill .ql-toolbar.ql-snow button:hover svg line{
  stroke: var(--color-interaction-primary-800);
}

.rich-text-editor-container .quill .ql-toolbar.ql-snow button:hover svg .ql-stroke{
  stroke: var(--color-interaction-primary-800);
}

.rich-text-editor-container .quill .ql-toolbar.ql-snow button:hover svg .ql-fill{
  fill: var(--color-interaction-primary-800);
}

.rich-text-editor-container .quill:focus-within .ql-container.ql-snow {
  border: 2px solid var(--color-info);
  border-bottom: none;
}

.rich-text-editor-container .quill:focus-within .ql-container.ql-snow .ql-editor{
  padding: 11px 14px 12px 14px;
}

.rich-text-editor-container .quill:focus-within .ql-container.ql-snow .ql-editor.ql-blank::before {
  left: 14px;
}

.rich-text-editor-container .quill:focus-within .ql-toolbar.ql-snow {
  padding: 8px 7px 7px 7px;
  border: 2px solid var(--color-info);
  border-top: 1px solid var(--color-stroke-300);
}

/* ERROR */

.rich-text-editor-container.error .quill .ql-container.ql-snow {
  border: 1px solid var(--color-danger-700);
  border-bottom: none;
}

.rich-text-editor-container.error .quill:focus-within .ql-container.ql-snow {
  border: 2px solid var(--color-danger-700);
  border-bottom: none;
}

.rich-text-editor-container.error .quill .ql-container.ql-snow .ql-editor{
  padding: 12px 15px;
}

.rich-text-editor-container.error .quill:focus-within .ql-container.ql-snow .ql-editor{
  padding: 11px 14px 12px 14px;
}

.rich-text-editor-container.error .quill .ql-container.ql-snow .ql-editor.ql-blank::before {
  left: 15px;
}

.rich-text-editor-container.error .quill:focus-within .ql-container.ql-snow .ql-editor.ql-blank::before {
  left: 14px;
}

.rich-text-editor-container.error .quill .ql-toolbar.ql-snow {
  padding: 8px;
  border: 1px solid var(--color-danger-700);
  border-top: 1px solid var(--color-stroke-300);
}

.rich-text-editor-container.error .quill:focus-within .ql-toolbar.ql-snow {
  padding: 8px 7px 7px 7px;
  border: 2px solid var(--color-danger-700);
  border-top: 1px solid var(--color-stroke-300);
}

/* ERROR */

/* DISABLED */

.rich-text-editor-container.disabled .quill .ql-container.ql-snow {
  background: var(--color-disabled);
}

.rich-text-editor-container.disabled .quill .ql-container.ql-snow .ql-editor {
  color: var(--color-text-icon-secondary);
}

.rich-text-editor-container.disabled .quill .ql-container.ql-snow .ql-editor > * {
  cursor: default;
}

.rich-text-editor-container.disabled .quill:focus-within .ql-container.ql-snow {
  border: 1px solid var(--color-stroke-300);
  border-bottom: none;
}

.rich-text-editor-container.disabled .quill:focus-within .ql-container.ql-snow .ql-editor{
  padding: 12px 15px;
}

.rich-text-editor-container.disabled .quill:focus-within .ql-container.ql-snow .ql-editor.ql-blank::before {
  left: 15px;
}

.rich-text-editor-container.disabled .quill:focus-within .ql-toolbar.ql-snow {
  padding: 8px;
  border: 1px solid var(--color-stroke-300);
}

.rich-text-editor-container.disabled .quill .ql-toolbar.ql-snow button:hover {
  cursor: default;
}

.rich-text-editor-container.disabled .quill .ql-toolbar.ql-snow button:hover svg line{
  stroke: var(--color-text-icon-secondary);
}

.rich-text-editor-container.disabled .quill .ql-toolbar.ql-snow button:hover svg .ql-stroke{
  stroke: var(--color-text-icon-secondary);
}

.rich-text-editor-container.disabled .quill .ql-toolbar.ql-snow button:hover svg .ql-fill{
  fill: var(--color-text-icon-secondary);
}

/* DISABLED */