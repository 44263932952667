.checkbox-container {
  width: 100%;
  float: left;
}

.checkbox-container .checkbox {
  float: left;
}

.checkbox-container .checkbox input {
	position: absolute;
	opacity: 0;
}

.checkbox-container .checkbox input + label {
	position: relative;
	cursor: pointer;
	padding: 8px 0px;
  margin: 0px;
  color: var(--color-text-icon-secondary);
  float: left;
}

.input-container .search-list .matched-items .item.group-header .checkbox input + label {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.02em;
}

.input-container .search-list .matched-items .item.group-item .checkbox input + label {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.checkbox-container .checkbox input + label:before {
	content: '';
	margin: -3px 8px 0px 0px;
  padding: 0px;
	display: inline-block;
	vertical-align: middle;
	width: 16px;
	height: 16px;
	background: url("../../../assets/other/checkbox-not-selected.svg") center center no-repeat;
	background-size: cover;
}

.checkbox-container .checkbox input:checked + label:before {
	width: 16px;
	height: 16px;
	background: url("../../../assets/other/checkbox-selected.svg") center center no-repeat;
	background-size: cover;
}

.checkbox-container .checkbox input:disabled + label:before {
	opacity: 0.50;
}