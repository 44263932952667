.right-fixed {
  position: absolute;
  right: 0px;
  z-index: 2;
}

.right-fixed .header {
  width: 100%;
  float: left;
}

.right-fixed .body {
  width: 100%;
  float: left;
}