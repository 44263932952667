.client-setup-proxy-advisors-step {
  width: 100%;
  margin-top: 64px;
  float: left;
}

.client-setup-proxy-advisors-step .proxy-advisors-table-bottom-container {
  width: 100%;
  float: left;
  margin-top: 24px;
}

.client-setup-proxy-advisors-step .proxy-advisors-table-bottom-container div {
  float: right;
}

.action-buttons-container {
  height: 40px;
  padding: 25px 32px 24px 32px;
  border-top: 1px solid var(--color-stroke-400);
  background-color: var(--color-white);
  position: fixed;
  bottom: 0px;
  margin-left: -32px;
  z-index: 15;
}

.action-buttons-container .left-buttons {
  float: left;
}

.action-buttons-container .right-buttons {
  float: right;
}

.action-buttons-container .right-buttons div {
  margin-right: 8px;
}

.action-buttons-container .right-buttons div:last-child {
  margin-right: 0px;
}