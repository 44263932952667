.client-details-subscription-details {
  width: 100%;
  float: left;
}

.client-details-subscription-details .subscription-details-form {
  width: 460px;
  float: left;
  /* margin: auto; */
}

.client-details-subscription-details .row {
  width: 100%;
  float: left;
  margin-bottom: 24px;
}

.client-details-subscription-details .action-button-container {
  float: right;
}

.client-details-subscription-details .action-button-container div {
  margin-right: 8px;
}

.client-details-subscription-details .action-button-container div:last-child {
  margin-right: 0px;
}