.client-setup-initial-step {
  width: 100%;
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.client-setup-initial-step .text-container {
  width: 100%;
  margin: 80px 0px 24px 0px;
}

.client-setup-initial-step .text-container h3 {
  margin: 0px 0px 6px 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -1px;
  text-align: center;
  color: var(--color-black);
}

.client-setup-initial-step .text-container p {
  margin: 6px 0px 0px 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--color-black);
}

.client-setup-initial-step .button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}