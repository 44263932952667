.client-details-general-info {
  width: 100%;
  float: left;
}

.client-details-general-info .company-information-form {
  width: 460px;
  float: left;
  margin-right: 32px;
}

.client-details-general-info .main-contact-form {
  width: 460px;
  float: left;
  margin-left: 32px;
}

.client-details-general-info .available-modules-form {
  width: 1048px;
  float: left;
}

.client-details-general-info .title {
  width: 100%;
  float: left;
  margin-bottom: 40px;
}

.client-details-general-info h3 {
  margin: 0px 0px 4px 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -1px;
  color: var(--color-black);
}

.client-details-general-info .row {
  width: 100%;
  float: left;
  margin-bottom: 24px;
}

.client-details-general-info .row .column.half {
  width: 50%;
  float: left;
}

.client-details-general-info .row .checkbox-group-label {
  width: 100%;
  padding: 0px 0px 8px 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #1D2433;
  float: left;
}

.client-details-general-info .action-button-container {
  float: right;
}

.client-details-general-info .action-button-container div {
  margin-right: 8px;
}

.client-details-general-info .action-button-container div:last-child {
  margin-right: 0px;
}

.client-details-general-info .client-logo-upload-container {
  margin-bottom: 12px;
  float: left;
}

.client-details-general-info .client-logo-upload-container .client-logo {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
  float: left;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--color-white);
}

.client-details-general-info .client-logo-upload-container .client-logo-upload-button {
  margin: 18px 0px 18px 16px;
  float: left;
}

.client-details-general-info .client-logo-upload-container .client-logo-upload-button input {
  display: none;
}

.client-details-general-info .client-logo-upload-container .client-logo-upload-button h3 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: var(--color-interaction-primary-700);
  cursor: pointer;
}