.page {
  width: 100%;
  height: 100%;
  float: left;
}

.page .container.with-account-manager-warning {
  margin-top: 65px;
}

.page .container.wide {
  width: calc(100% - 120px);
  padding: 48px 32px;
  float: left;
  margin-left: 56px;
}

.page .container.narrow {
  width: calc(100% - 320px);
  padding: 48px 32px;
  float: left;
  margin-left: 256px;
}

.page-header {
  width: 100%;
  float: left;
}

.page-header .section .row {
  max-width: 100%;
  width: 100%;
  float: left;
  margin-bottom: 8px;
}

.page-header h1 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: -1px;
  float: left;
}

.page-header .section .row .title {
  float: left;
}

.page-body {
  width: 100%;
  float: left;
}

.page-body.additonal-top-margin {
  margin-top: 16px;
}

.page-body .tab-container {
  width: 100%;
  float: left;
}

.page-body .investor-list-buttons {
  float: right;
  margin-bottom: 24px;
}

.page-body .investor-list-buttons .right-buttons {
  width: 100%;
  float: right;
}

.page-body .investor-list-buttons .right-buttons  div {
  margin-right: 8px;
}

.page-body .investor-list-buttons .right-buttons div:last-child {
  margin-right: 0px;
}

.page-body .investor-list-buttons .new-engagement-options {
  position: relative;
  width: 100%;
}

.page-body .investor-list-buttons .new-engagement-options .option-container {
  width: 288px;
  max-height: 212px;
  position: absolute;
  top: 48px;
  right: 0px;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  z-index: 3;
}

.page-body .investor-list-buttons .new-engagement-options .option-container .avaliable-options {
  width: 100%;
  max-height: 160px;
  background-color: var(--color-white);
  border-radius: 6px;
  z-index: 10;
  overflow: auto;
}

.page-body .investor-list-buttons .new-engagement-options .option-container .avaliable-options .option {
  width: calc(100% - 32px);
  padding: 8px 16px;
  background: var(--color-white);
  float: left;
  position: relative;
}

.page-body .investor-list-buttons .new-engagement-options .option-container .avaliable-options .option:hover {
  background: var(--color-interaction-primary-100);
  cursor: pointer;
}

.page-body .investor-list-buttons .new-engagement-options .option-container .avaliable-options .option p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--color-text-icon-primary);
  float: left;
}

.engagement-meeting-request-list-container {
  width: 100%;
  float: left;
  margin-top: 24px;
}

.engagement-shareholder-communication-list-container {
  width: 100%;
  float: left;
  margin-top: 24px;
}

.engagement-meeting-note-list-container {
  width: 100%;
  float: left;
  margin-top: 24px;
}

.engagement-other-note-list-container {
  width: 100%;
  float: left;
  margin-top: 24px;
}

.no-enagements-div {
  width: calc(100% - 2px);
  height: 348px;
  border: 1px solid var(--color-stroke-400);
  border-radius: 8px;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  float: left;
  margin-top: 24px;
}

.no-enagements-div .icon-container {
  width: 44px;
  height: 44px;
  border-radius: 4px;
  background-color: var(--color-neutral-300);
}

.no-enagements-div .icon-container .icon {
  width: 18px;
  height: 14px;
  margin: 15px 13px;
  background: url("../../../assets/icons/list.svg") center center no-repeat;
  background-size: cover;
}

.no-enagements-div .text-container {
  width: 100%;
  margin: 16px 0px;
}

.no-enagements-div .text-container h4 {
  margin: 0px 0px 6px 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: center;
  color: var(--color-black);
}

.no-enagements-div .text-container p {
  margin: 6px 0px 0px 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--color-black);
}

.no-enagements-div .button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}