.modal-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 100;
}

.modal-container .overlay {
  width: 100%;
  height: 100%;
  background-color: #0A0D14;
  opacity: 0.15;
  z-index: 100;
}

.modal-container .content {
  width: 800px;
  padding: 32px 40px;
  background-color: var(--color-white);
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  position: absolute;
  /*top: 200px;*/
  top: 120px;
  left: calc(calc(100% - 880px) / 2);
}

.modal-container .content .header {
  width: 100%;
  float: left;
}

.modal-container .content .header .title {
  float: left;
  margin-right: 12px;
}

.modal-container .content .header .title h2 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -1px;
  color: var(--color-text-icon-primary);
}

.modal-container .content .header .type-chip {
  float: left;
  margin: 12px 0px;
}

.modal-container .content .header .type-chip .icon {
  width: 16px;
  height: 16px;
  float: left;
}

.modal-container .content .header .type-chip .icon.meeting-request {
  background: url("../../../assets/icons/mail/mail-text-icon-primary.svg") center center no-repeat;
  background-size: cover;
}

.modal-container .content .header .type-chip .label {
  float: left;
  margin-left: 8px;
}

.modal-container .content .header .type-chip .label p {
  padding: 2px 0px;
  margin: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.02em;
  color: var(--color-text-icon-primary);
}

.modal-container .content .header .close-button {
  width: 32px;
  height: 32px;
  float: right;
}

.modal-container .content .header .close-button .close-icon {
  width: 14px;
  height: 14px;
  margin: 13px 0px 13px 18px;
  background: url("../../../assets/icons/cross.svg") center center no-repeat;
  background-size: cover;
  cursor: pointer;
}

.modal-container .content .header .history {
  width: 100%;
  float: left;
  margin-top: 4px;
}

.modal-container .content .header .history .creation-info {
  float: left;
}

.modal-container .content .header .history .creation-info p {
  padding: 0px;
  margin: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text-icon-secondary);
}

.modal-container .content .header .history .separator {
  width: 1px;
  height: 12px;
  float: left;
  background-color: var(--color-stroke-300);
  margin: 4px 16px;
}

.modal-container .content .header .history .update-info {
  float: left;
}

.modal-container .content .header .history .update-info p {
  padding: 0px;
  margin: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text-icon-secondary);
}

.modal-container .content .body {
  width: 100%;
  float: left;
  margin-top: 32px;
}

.modal-container .content .body .email-subject {
  width: 100%;
  float: left;
  margin-bottom: 8px;
}

.modal-container .content .body .email-subject h4 {
  padding: 0px;
  margin: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.32px;
  color: var(--color-text-icon-secondary);
}

.modal-container .content .body .email-body {
  width: calc(100% - 24px);
  height: 300px;
  float: left;
  padding: 12px;
  background-color: var(--color-neutral-100);
  overflow: auto;
}

.modal-container .content .body .email-body p {
  padding: 0px;
  margin: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-icon-secondary);
}

.modal-container .content .footer {
  width: 100%;
  float: left;
  margin-top: 32px;
}

.modal-container .content .footer .modal-button{
  float: right;
  margin-left: 8px;
}

.modal-container .content .footer .modal-button:last-child{
  margin-left: 0px;
}