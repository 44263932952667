.login-page {
  width: 100%;
  height: 100%;
  background: #00263d;
  background: linear-gradient(45deg, #00263d 25%, #26658b 100%);
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  align-items: center;
}

.login-page .login-form-container {
  width: 372px;
  margin: auto;
  padding: 96px 64px;
  background-color: var(--color-white);
  box-shadow: 0px 4px 4px 0px #021724;
  border-radius: 16px;
}

.login-page .login-form-container .logo-area {
  width: 100%;
  float: left;
}

.login-page .login-form-container .logo-area .logo {
  width: 170px;
  height: 56px;
  margin: auto;
  background: url("../../../assets/logo/logo-full-dark.svg");
}

.login-page .login-form-container .title {
  width: 100%;
  float: left;
  padding: 48px 0px;
}

.login-page .login-form-container .title.no-bottom-padding {
  padding-bottom: 0px;
}

.login-page .login-form-container .title h3 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -1px;
  color: var(--color-text-icon-primary);
  text-align: center;
}

.login-page .login-form-container .description {
  width: 100%;
  float: left;
}

.login-page .login-form-container .description p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--color-text-icon-primary);
  text-align: center;
}

.login-page .login-form-container .note {
  width: 100%;
  float: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.login-page .login-form-container .note p {
  float: left;
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--color-text-icon-secondary);
}

.login-page .login-form-container .note .send-code-button {
  float: left;
  margin: 2px 0px 0px 4px;
}

.login-page .login-form-container .form-row {
  width: 100%;
  float: left;
  margin-bottom: 24px;
}

.login-page .login-form-container .form-row .form-error {
  width: calc(100% - 50px);
  padding: 16px 24px;
  float: left;
  border: 1px solid var(--color-danger-200);
  border-radius: 8px;
  background-color: var(--color-danger-100);
}

.login-page .login-form-container .form-row .form-error .error-title {
  width: 100%;
  float: left;
}

.login-page .login-form-container .form-row .form-error .error-title .warning-icon {
  width: 22px;
  height: 22px;
  float: left;
  background: url("../../../assets/icons/information/information-critical.svg") center center no-repeat;
  background-size: cover;
  margin: -3px 3px 0px 0px;
}

.login-page .login-form-container .form-row .form-error .error-title h4 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.28px;
  color: var(--color-text-icon-primary);
}

.login-page .login-form-container .form-row .form-error .error-content {
  width: 100%;
  float: left;
  margin-top: 9px;
}

.login-page .login-form-container .form-row .form-error .error-content p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--color-text-icon-primary);
}

.login-page .login-form-container .form-row .form-info {
  width: calc(100% - 50px);
  padding: 16px 24px;
  float: left;
  border: 1px solid var(--color-interaction-primary-200);
  border-radius: 8px;
  background-color: var(--color-interaction-primary-100);
}

.login-page .login-form-container .form-row .form-info .info-title {
  width: 100%;
  float: left;
}

.login-page .login-form-container .form-row .form-info .info-title .warning-icon {
  width: 22px;
  height: 22px;
  float: left;
  background: url("../../../assets/icons/information/information-notice.svg") center center no-repeat;
  background-size: cover;
  margin: -3px 3px 0px 0px;
}

.login-page .login-form-container .form-row .form-info .info-title h4 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.28px;
  color: var(--color-text-icon-primary);
}

.login-page .login-form-container .form-row .form-info .info-content {
  width: 100%;
  float: left;
  margin-top: 9px;
}

.login-page .login-form-container .form-row .form-info .info-content p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--color-text-icon-primary);
}

.login-page .login-form-container .form-row .code-area {
  width: 308px;
  margin: 0px 32px;
  float: left;
}

.login-page .login-form-container .form-row .code-area .code-box {
  width: 40px;
  float: left;
  margin-right: 8px;
}

.login-page .login-form-container .form-row .code-area .code-box:last-child {
  margin-right: 0px;
}

.login-page .login-form-container .form-row .code-area .code-box input {
  text-align: center;
  float: left;
}

.login-page .login-form-container .form-row .code-separator {
  width: 12px;
  border-top: 1px solid var(--color-text-icon-disabled);
  border-bottom: 1px solid var(--color-text-icon-disabled);
  margin: 19px 12px 19px 4px;
  float: left;
}
