.react-datepicker {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--color-text-icon-primary);
  border: none;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
}

.react-datepicker .react-datepicker__header{
  background-color: var(--color-white);
  border: none;
  padding: 20px 0px 8px 0px;
}

.react-datepicker__navigation {
  top: 16px;
  border: 1px solid var(--color-neutral-300);
  border-radius: 8px;
}

.react-datepicker__navigation:hover {
  background-color: var(--color-neutral-200);
}

.react-datepicker__navigation--previous {
  left: 16px;
}

.react-datepicker__navigation--next {
  right: 16px;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon--previous::before {
  width: 4px;
  height: 8px;
  border: none;
  background: url("../../../assets/icons/caret-left/caret-left-text-icon-primary.svg") center center no-repeat;
  background-size: cover;
  transform: none;
  left: -4px;
  top: 8px;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon--next::before {
  width: 4px;
  height: 8px;
  border: none;
  background: url("../../../assets/icons/caret-right/caret-right-text-icon-primary.svg") center center no-repeat;
  background-size: cover;
  transform: none;
  left: 0px;
  top: 8px;
}

/*
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  border-color: var(--color-text-icon-primary);
}
*/

.react-datepicker__month {
  margin: 0px 8px 8px 8px;
}

.react-datepicker__day-names {
  margin-top: 6px;
}

.react-datepicker__day-name {
  width: 30px;
  margin: 4px;
  padding: 7px 0px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-text-icon-secondary);
  text-align: center;
}

.react-datepicker__day {
  width: 30px;
  margin: 4px;
  padding: 7px 0px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-text-icon-primary);
  text-align: center;
}

.react-datepicker__day--keyboard-selected {
  background-color: transparent;
}

.react-datepicker__day:hover {
  border-radius: 50%;
  background-color: var(--color-interaction-primary-100);
  color: var(--color-interaction-primary-700);
}

.react-datepicker__day--selected {
  border-radius: 50%;
  background-color: var(--color-info);
  color: var(--color-white);
}

.react-datepicker__day--selected:hover {
  border-radius: 50%;
  background-color: var(--color-info);
  color: var(--color-white);
}

.react-datepicker__day--today {
  color: var(--color-interaction-primary-700);
}

.react-datepicker__day--today.react-datepicker__day--selected {
  color: var(--color-white);
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker-popper {
  z-index: 10;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  border-bottom-color: var(--color-stroke-300);
}

.react-datepicker__current-month {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-icon-primary);
}