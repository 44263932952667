.info-box {
  width: calc(100% - 682px);
  float: left;
  margin: 64px 32px 0px 0px;
}

.info-box .item {
  width: 100%;
  float: left;
  padding: 16px 0px;
  border-bottom: 1px solid var(--color-neutral-300);
}

.info-box .item:first-child {
  padding-top: 0px;
}

.info-box .item:last-child {
  padding-bottom: 0px;
  border-bottom: none;
}

.info-box .item .text {
  float: left;
  margin: 4px 0px;
}

.info-box .item .text h4 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.28px;
  color: var(--color-text-icon-primary);
}

.info-box .item .icon {
  float: right;
  font-size: 16px;
  margin: 2px 0px 1px 0px;
}

.info-box .item .icon .not-emoji {
  width: 16px;
  height: 12px;
  margin: 4px 0px 5px 0px;
  -webkit-box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.15);
  -moz-box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.15);
  box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.15);
}

.graph {
  width: 650px;
  float: left;
  margin-top: 32px;
}