.client-setup-general-info-step {
  width: 100%;
  margin-top: 40px;
  float: left;
}

.client-setup-general-info-step .company-information-form {
  /*width: calc(50% - 32px);*/
  width: 460px;
  float: left;
  margin-right: 32px;
}

.client-setup-general-info-step .company-information-form .client-logo-upload-container {
  float: left;
  margin: 40px 0px 16px 0px;
}

.client-setup-general-info-step .company-information-form .client-logo-upload-container .client-logo {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
  float: left;
  border: 2px dashed var(--color-interaction-primary-300);
}

.client-setup-general-info-step .company-information-form .client-logo-upload-container .client-logo .logo {
  width: 48px;
  height: 48px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: -1;
  opacity: 0.15;
  border-radius: 50%;
}

.client-setup-general-info-step .company-information-form .client-logo-upload-container label .plus-icon {
  width: 21px;
  height: 21px;
  background: url("../../../../assets/icons/plus/plus-interaction-primary-700.svg") center center no-repeat;
  background-size: cover;
  margin: 13.5px auto 13.5px auto;
  cursor: pointer;
}

.client-setup-general-info-step .company-information-form .client-logo-upload-container input {
  display: none;
}

.client-setup-general-info-step .company-information-form .client-logo-upload-container h4 {
  float: left;
  margin: 18px 0px 18px 8px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: var(--color-text-icon-secondary);
}

.client-setup-general-info-step .main-contact-form {
  /*width: calc(50% - 32px);*/
  width: 460px;
  float: left;
  margin-left: 32px;
}

.client-setup-general-info-step .title {
  width: 100%;
  float: left;
}

.client-setup-general-info-step h3 {
  margin: 0px 0px 4px 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -1px;
  color: var(--color-black);
}

.client-setup-general-info-step .row {
  width: 100%;
  float: left;
  margin-bottom: 24px;
}

.action-buttons-container {
  height: 40px;
  padding: 25px 32px 24px 32px;
  border-top: 1px solid var(--color-stroke-400);
  background-color: var(--color-white);
  position: fixed;
  bottom: 0px;
  margin-left: -32px;
  z-index: 5;
}

.action-buttons-container .left-buttons {
  float: left;
}

.action-buttons-container .right-buttons {
  float: right;
}

.action-buttons-container .right-buttons div {
  margin-right: 8px;
}

.action-buttons-container .right-buttons div:last-child {
  margin-right: 0px;
}