.dropdown-item {
  width: calc(100% - 32px);
  padding: 8px 16px;
  background: var(--color-white);
  float: left;
  position: relative;
}

.dropdown-item:hover {
  background: var(--color-interaction-primary-100);
  cursor: pointer;
}

.dropdown-item .chip {
  width: 8px;
  height: 8px;
  float: left;
  margin: 8px 8px 8px 0px;
  border-radius: 50%;
}

.dropdown-item .chip.success {
  background-color: var(--color-success-500);
}

.dropdown-item .chip.warning {
  background-color: var(--color-warning-700);
}

.dropdown-item .chip.danger {
  background-color: var(--color-danger-700);
}

.dropdown-item .chip.unknown {
  background-color: var(--color-text-icon-secondary);
}

.dropdown-item .chip.info {
  background-color: var(--color-interaction-primary-700);
}

.dropdown-item p {
  width: calc(100% - 21px);
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--color-text-icon-primary);
  float: left;
}

.dropdown-item .selected-icon {
  width: 13px;
  height: 9px;
  background: url("../../../assets/icons/dropdown-selected.svg") center center no-repeat;
  background-size: cover;
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
}