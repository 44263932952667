.warning-container { 
  height: 32px;
  padding: 16px 32px;
  border-bottom: 1px solid var(--color-stroke-400);
  background-color: var(--color-white);
  position: fixed;
  top: 0px;
}

.warning-container.wide { 
  width: calc(100% - 120px);
  left: 56px;
}

.warning-container.narrow { 
  width: calc(100% - 320px);
  left: 256px;
}

.warning-container .title {
  min-width: 600px;
  float: left;
}

.warning-container .title h3 {
  padding: 8px 0px;
  margin: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: var(--color-text-icon-primary);
}

.warning-container .title .icon {
  width: 30px;
  height: 30px;
  float: left;
  margin-right: 8px;
}

.warning-container .title .icon.warning {
  background: url("../../assets/icons/information/information-warning.svg") center center no-repeat;
  background-size: cover;
}

.warning-container .button-container {
  float: right;
}