.update-investor-list-container {
  width: 100%;
  float: left;
}

.update-investor-list-container .update-investor-list-content {
  width: 460px;
  float: left;
  border-top: 1px solid var(--color-neutral-300);
}

.update-investor-list-container .update-investor-list-content .title-container {
  float: left;
  margin: 32px 0px;
}

.update-investor-list-container .update-investor-list-content .title-container h3 {
  margin: 8px 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.32px;
  float: left;
  color: var(--color-text-icon-primary);
}

.update-investor-list-container .update-investor-list-content .button-container {
  float: right;
  margin: 32px 0px;
}

.update-investor-list-container .modal-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 100;
}

.update-investor-list-container .modal-container .overlay {
  width: 100%;
  height: 100%;
  background-color: #0A0D14;
  opacity: 0.15;
  z-index: 100;
}

.update-investor-list-container .modal-container .content {
  width: 580px;
  padding: 32px 40px;
  background-color: var(--color-white);
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  position: absolute;
  /*top: 200px;*/
  top: 120px;
  left: calc(calc(100% - 660px) / 2);
}

.update-investor-list-container .modal-container .content .header {
  width: 100%;
  float: left;
}

.update-investor-list-container .modal-container .content .header .title {
  float: left;
  margin-right: 12px;
}

.update-investor-list-container .modal-container .content .header .title h3 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -1px;
  color: var(--color-text-icon-primary);
}

.update-investor-list-container .modal-container .content .header .close-button {
  width: 32px;
  height: 32px;
  float: right;
}

.update-investor-list-container .modal-container .content .header .close-button .close-icon {
  width: 14px;
  height: 14px;
  margin: 9px 0px 9px 18px;
  background: url("../../../../assets/icons/cross.svg") center center no-repeat;
  background-size: cover;
  cursor: pointer;
}

.update-investor-list-container .modal-container .content .body {
  width: 100%;
  float: left;
  margin-top: 32px;
}

.update-investor-list-container .modal-container .content .body .new-attachment-container {
  width: 100%;
  float: left;
}

.update-investor-list-container .modal-container .content .body .new-attachment-container .new-attachment-button {
  padding: 8px;
  border: 1px solid var(--color-stroke-400);
  border-radius: 2px;
  float: left;
  cursor: pointer;
}

.update-investor-list-container .modal-container .content .body .new-attachment-container .new-attachment-button input {
  display: none;
}

.update-investor-list-container .modal-container .content .body .new-attachment-container .new-attachment-button .new-attachment-icon {
  width: 15px;
  height: 16px;
  background: url("../../../../assets/icons/attachment/attachment-interaction-primary-700.svg") center center no-repeat;
  background-size: inherit;
  float: left;
  margin-right: 8px;
}

.update-investor-list-container .modal-container .content .body .new-attachment-container .new-attachment-button .new-attachment-text {
  float: left;
  margin: 2px 0px;
}

.update-investor-list-container .modal-container .content .body .new-attachment-container .new-attachment-button .new-attachment-text h4 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.02em;
  color: var(--color-interaction-primary-700);
}

.update-investor-list-container .modal-container .content .body .attachment-note-container {
  width: 100%;
  float: left;
  margin: 8px 0px 32px 0px;
}

.update-investor-list-container .modal-container .content .body .attachment-note-container p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  float: left;
  color: var(--color-text-icon-primary);
}

.update-investor-list-container .modal-container .content .body .attachment-note-container p a {
  color: var(--color-interaction-primary-700);
  font-weight: 600;
  text-decoration: none;
}

.update-investor-list-container .modal-container .content .body .attached-item-container {
  float: left;
  margin: 0px;
}

.update-investor-list-container .modal-container .content .body .attached-item-container .attached-item {
  padding: 8px;
  border: 1px solid var(--color-stroke-400);
  border-radius: 2px;
  float: left;
}

.update-investor-list-container .modal-container .content .body .attached-item-container .attached-item .attached-item-icon {
  width: 15px;
  height: 16px;
  background: url("../../../../assets/icons/attachment/attachment-interaction-primary-700.svg") center center no-repeat;
  background-size: inherit;
  float: left;
  margin-right: 8px;
}

.update-investor-list-container .modal-container .content .body .attached-item-container .attached-item .attached-item-text {
  float: left;
  margin: 2px 0px;
}

.update-investor-list-container .modal-container .content .body .attached-item-container .attached-item .attached-item-text.clickable {
  cursor: pointer;
}

.update-investor-list-container .modal-container .content .body .attached-item-container .attached-item .attached-item-text h4 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.02em;
  color: var(--color-interaction-primary-700);
}

.update-investor-list-container .modal-container .content .body .attached-item-container .attached-item .remove-button {
  width: 8px;
  height: 8px;
  background: url("../../../../assets/icons/cross.svg") center center no-repeat;
  background-size: cover;
  float: left;
  margin: 4px 0px 4px 8px;
  cursor: pointer;
}

.update-investor-list-container .modal-container .content .footer {
  width: 100%;
  float: left;
  margin-top: 32px;
}

.update-investor-list-container .modal-container .content .footer .modal-button {
  float: right;
  margin-left: 8px;
}

.update-investor-list-container .modal-container .content .footer .modal-button:last-child {
  margin-left: 0px;
}