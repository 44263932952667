.client-setup-final-step {
  width: 100%;
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.client-setup-final-step .text-container {
  width: 100%;
  margin: 80px 0px 24px 0px;
}

.client-setup-final-step .text-container h3 {
  margin: 0px 0px 6px 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -1px;
  text-align: center;
  color: var(--color-black);
}

.client-setup-final-step .text-container p {
  margin: 6px 0px 0px 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--color-black);
}

.client-setup-final-step .error-list-container {
  width: 450px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.client-setup-final-step .error-list-container .error-box {
  width: 400px;
  float: left;
  padding: 24px;
  margin: 0px auto 6px auto;
  border: 1px solid var(--color-danger-200);
  border-radius: 8px;
  background-color: var(--color-danger-100);
}

.client-setup-final-step .error-list-container .error-box:last-child {
  margin: 0px auto 0px auto;
}

.client-setup-final-step .error-list-container .error-box .step-number {
  width: 22px;
  height: 22px;
  border: 1px solid var(--color-danger-700);
  border-radius: 50%;
  background-color: var(--color-white);
  float: left;
}

.client-setup-final-step .error-list-container .error-box .step-number h3 {
  margin: 3px 0px;
  padding: 0px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.05px;
  color: var(--color-danger-700);
}

.client-setup-final-step .error-list-container .error-box .content {
  width: calc(100% - 80px);
  float: left;
  margin: -2px 0px 0px 8px;
}

.client-setup-final-step .error-list-container .error-box .content .title {
  width: 100%;
  float: left;
}

.client-setup-final-step .error-list-container .error-box .content .title h3 {
  margin: 0px;
  padding: 0px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.05px;
  color: var(--color-danger-700);
}

.client-setup-final-step .error-list-container .error-box .content .subtitle {
  width: 100%;
  float: left;
}

.client-setup-final-step .error-list-container .error-box .content .subtitle h4 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.02em;
  color: var(--color-text-icon-disabled);
}

.client-setup-final-step .button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.client-setup-final-step .button-container div {
  margin-right: 8px;
}

.client-setup-final-step .button-container div:last-child {
  margin-right: 0px;
}