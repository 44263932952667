.col-item {
  margin-right: 16px;
}

.col-item:last-child {
  margin-right: 0px;
}

.single-col {
  width: calc(calc(100% - 64px) / 5);
  float: left;
}

.double-col {
  width: calc(calc(calc(calc(100% - 64px) / 5) * 2) + 16px);
  float: left;
}

.full-size-col {
  width: 100%;
  float: left;
}

.label {
  width: 100%;
  float: left;
  margin-bottom: 8px;
}

.label h4 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: var(--color-text-icon-primary);
  float: left;
}

.label .tooltip-icon {
  width: 13px;
  height: 13px;
  float: left;
  margin: 2px 0px 1px 8px;
  background: url("../../../../../assets/icons/information/information-tooltip.svg") no-repeat center center;
  background-size: cover;
  cursor: pointer;
}

.value {
  width: 100%;
  float: left;
}

.value .text p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-icon-secondary);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.value .text-block {
  width: calc(100% - 24px);
  height: 168px;
  padding: 16px 12px 16px 12px;
  background-color: var(--color-neutral-200);
  overflow: auto;
}

.value .text-block p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-icon-secondary);
}

.value .url p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-icon-secondary);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.value .url p a {
  color: var(--color-interaction-primary-700);
  text-decoration: none;
}

.value .group {
  width: 100%;
  float: left;
  padding: 4px 0px 32px 0px;
  position: relative;
}

.value .group::before {
  width: 1px;
  height: calc(100% + 24px);
  content: '';
  border-left: 1px solid var(--color-neutral-300);
  position: absolute;
  left: -40px;
  top: -24px;
}
.value .group.edit-mode::before {
  display: none;
}

.value .group::after {
  width: 1px;
  height: calc(100% + 24px);
  content: '';
  border-right: 1px solid var(--color-neutral-300);
  position: absolute;
  right: 23px;
  top: -24px;
}

.value .group.edit-mode::after {
  display: none;
}

.value .group .sub-item {
  width: calc(50% - 8px);
  float: left;
}

.value .group .sub-item:nth-child(odd){
  margin: 20px 8px 0px 0px;
}

.value .group .sub-item:nth-child(even){
  margin: 20px 0px 0px 8px;
}

.value .indicator {
  width: 91px;
  height: 45px;
  float: left;
  position: relative;
  margin-top: 16px;
}

.value .indicator .level {
  width: 90px;
  aspect-ratio: 1;
  display: inline-grid;
  place-content: center;
  font-size: 25px;
  font-weight: bold;
  font-family: sans-serif;
  position: absolute;
  left: 0px;
}

.value .indicator .level:before {
  content: "";
  position: absolute;
  border-radius: 50%;
  inset: 0;
  -webkit-mask:radial-gradient(farthest-side,#0000 calc(99% - 12px),#000 calc(100% - 12px));
  mask:radial-gradient(farthest-side,#0000 calc(99% - 12px),#000 calc(100% - 12px));
}

.value .indicator .level.first-level.active:before {
  /*background: conic-gradient(#BAE6FD calc(16.66 * 1%),#0000 0);*/
  background: conic-gradient(#4BAC8C calc(16.66 * 1%),#0000 0);
  rotate: -90deg;
}

.value .indicator .level.second-level.active:before {
  /*background: conic-gradient(#60A5FA calc(16.66 * 1%),#0000 0);*/
  background: conic-gradient(#F5A200 calc(16.66 * 1%),#0000 0);
  rotate: -30deg;
}

.value .indicator .level.third-level.active:before {
  /*background: conic-gradient(#1D4ED8 calc(16.66 * 1%),#0000 0);*/
  background: conic-gradient(#E02D3C calc(16.66 * 1%),#0000 0);
  rotate: 30deg;
}

.value .indicator .level.first-level.passive:before {
  background: conic-gradient(var(--color-neutral-200) calc(16.66 * 1%),#0000 0);
  rotate: -90deg;
}

.value .indicator .level.second-level.passive:before {
  background: conic-gradient(var(--color-neutral-300) calc(16.66 * 1%),#0000 0);
  rotate: -30deg;
}

.value .indicator .level.third-level.passive:before {
  background: conic-gradient(var(--color-neutral-600) calc(16.66 * 1%),#0000 0);
  rotate: 30deg;
}

.value .indicator .level.first-level.active-reverse:before {
  background: conic-gradient(#E02D3C calc(16.66 * 1%),#0000 0);
  rotate: -90deg;
}

.value .indicator .level.second-level.active-reverse:before {
  background: conic-gradient(#F5A200 calc(16.66 * 1%),#0000 0);
  rotate: -30deg;
}

.value .indicator .level.third-level.active-reverse:before {
  background: conic-gradient(#4BAC8C calc(16.66 * 1%),#0000 0);
  rotate: 30deg;
}

.value .indicator .level.first-level.passive-reverse:before {
  background: conic-gradient(var(--color-neutral-600) calc(16.66 * 1%),#0000 0);
  rotate: -90deg;
}

.value .indicator .level.second-level.passive-reverse:before {
  background: conic-gradient(var(--color-neutral-300) calc(16.66 * 1%),#0000 0);
  rotate: -30deg;
}

.value .indicator .level.third-level.passive-reverse:before {
  background: conic-gradient(var(--color-neutral-200) calc(16.66 * 1%),#0000 0);
  rotate: 30deg;
}

.value .indicator .pin-container {
  width: 90px;
  height: 90px;
  position: absolute;
}

.value .indicator .pin-container.first-level{
  width: 90px;
  height: 90px;
  position: absolute;
  top: -1.5px;
  left: 1px;
  rotate: -60deg;
}

.value .indicator .pin-container.second-level{
  width: 90px;
  height: 90px;
  position: absolute;
  top: 0px;
  rotate: 0deg;
}

.value .indicator .pin-container.third-level{
  width: 90px;
  height: 90px;
  position: absolute;
  top: -0.5px;
  left: -2px;
  rotate: 60deg;
}

.value .indicator .pin-container.active .pin {
  width: 5px;
  height: 40px;
  background: url("../../../../../assets/other/indicator-pin.svg") no-repeat center center;
  background-size: cover;
  margin: 6px auto 0px auto;
  position: relative;
  z-index: 10;
}

.value .indicator .pin-container.passive .pin {
  width: 5px;
  height: 5px;
  background: url("../../../../../assets/other/indicator-pin-dot.svg") no-repeat center center;
  background-size: cover;
  margin: 40px auto 0px auto;
  position: relative;
  z-index: 10;
}

.value .indicator .pin-container.active-reverse .pin {
  width: 5px;
  height: 40px;
  background: url("../../../../../assets/other/indicator-pin.svg") no-repeat center center;
  background-size: cover;
  margin: 6px auto 0px auto;
  position: relative;
  z-index: 10;
}

.value .indicator .pin-container.passive-reverse .pin {
  width: 5px;
  height: 5px;
  background: url("../../../../../assets/other/indicator-pin-dot.svg") no-repeat center center;
  background-size: cover;
  margin: 40px auto 0px auto;
  position: relative;
  z-index: 10;
}

.value .indicator-text {
  float: left;
  margin: 29px 0px 12px 8px;
}

.value .indicator-text p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text-icon-secondary);
}