.page {
  width: 100%;
  height: 100%;
  float: left;
}

.page .container.with-account-manager-warning {
  margin-top: 65px;
}

.page .container.wide {
  width: calc(100% - 120px);
  padding: 48px 32px;
  float: left;
  margin-left: 56px;
}

.page .container.narrow {
  width: calc(100% - 320px);
  padding: 48px 32px;
  float: left;
  margin-left: 256px;
}

.page-header {
  width: 100%;
  float: left;
}

.page-header .section .row {
  max-width: 100%;
  width: 100%;
  float: left;
  margin-bottom: 24px;
}

.page-header h1 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: -1px;
  float: left;
}

.page-header .section .row .title {
  float: left;
}

.page-header .section .row .search-box {
  float: right;
  margin: 6px 0px;
}

.page-body {
  width: 100%;
  float: left;
}

.page-body .investor-list-buttons {
  float: right;
  margin-bottom: 24px;
}

.page-body .investor-list-buttons div {
  margin-right: 8px;
}

.page-body .investor-list-buttons div:last-child {
  margin-right: 0px;
}