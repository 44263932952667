.change-password-container {
  width: 100%;
  float: left;
}

.change-password-container .change-password-content {
  width: 460px;
  float: left;
  margin-top: 32px;
  border-top: 1px solid var(--color-neutral-300);
}

.change-password-container .change-password-content  .title-container {
  float: left;
  margin: 32px 0px;
}

.change-password-container .change-password-content  .title-container h3 {
  margin: 8px 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.32px;
  float: left;
  color: var(--color-text-icon-primary);
}

.change-password-container .change-password-content  .button-container {
  float: right;
  margin: 32px 0px;
}

.change-password-container .change-password-form {
  width: 460px;
  float: left;
}

.change-password-container .change-password-form .row {
  width: 100%;
  float: left;
  margin-bottom: 24px;
}

.change-password-container .change-password-form .new-password-conditions {
  width: 100%;
  float: left;
}

.change-password-container .change-password-form .new-password-conditions .conditions-note {
  width: 100%;
  float: left;
  margin: 8px 0px;
}

.change-password-container .change-password-form .new-password-conditions .conditions-note p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  float: left;
  color: var(--color-text-icon-secondary);
}

.change-password-container .change-password-form .new-password-conditions .conditions-list {
  width: calc(100% - 16px);
  float: left;
  margin: 0px;
  padding: 0px 0px 0px 16px;
}

.change-password-container .change-password-form .new-password-conditions .conditions-list li {
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  float: left;
  color: var(--color-text-icon-secondary);
}

.change-password-container .change-password-form .new-password-conditions .conditions-list li::before {
  color: var(--color-text-icon-secondary);
}

.change-password-container .change-password-form .new-password-conditions .conditions-list li.success {
  color: var(--color-success-700);
}

.change-password-container .change-password-form .new-password-conditions .conditions-list li.error {
  color: var(--color-danger-700);
}

.change-password-container .change-password-form .form-button {
  float: right;
  margin-left: 8px;
}

.change-password-container .change-password-form .form-button:last-child {
  margin-left: 0px;
}

.change-password-container .change-password-form .form-error {
  width: calc(100% - 50px);
  padding: 16px 24px;
  float: left;
  border: 1px solid var(--color-danger-200);
  border-radius: 8px;
  background-color: var(--color-danger-100);
}

.change-password-container .change-password-form .form-error .error-title {
  width: 100%;
  float: left;
}

.change-password-container .change-password-form .form-error .error-title .warning-icon {
  width: 22px;
  height: 22px;
  float: left;
  background: url("../../../../assets/icons/information/information-critical.svg") center center no-repeat;
  background-size: cover;
  margin: -3px 3px 0px 0px;
}

.change-password-container .change-password-form .form-error .error-title h4 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.28px;
  color: var(--color-text-icon-primary);
}

.change-password-container .change-password-form .form-error .error-content {
  width: 100%;
  float: left;
  margin-top: 9px;
}

.change-password-container .change-password-form .form-error .error-content p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--color-text-icon-primary);
}