.input-container {
  width: 300px;
}

.input-container .input-wrapper {
  width: 100%;
  position: relative;
  float: left;
}

.input-container label.input-label {
  width: 100%;
  padding: 0px 0px 8px 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #1D2433;
  float: left;
}

.input-container input.password-input {
  width: calc(100% - 16px);
  padding: 7px 7px;
  background: var(--color-white);
  border: 1px solid var(--color-stroke-300);
  border-radius: 8px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-icon-primary);
  float: left;
}

.input-container input.password-input:focus {
  width: calc(100% - 14px);
  padding: 6px 6px;
  outline: none !important;
  border: 2px solid var(--color-info);
}

.input-container input.password-input::placeholder {
  color: var(--color-text-icon-secondary);
}

.input-container input.password-input:disabled {
  background: var(--color-disabled);
}

.input-container input.password-input.error {
  border: 1px solid var(--color-danger-700);
}

.input-container input.password-input.error:focus {
  width: calc(100% - 14px);
  padding: 6px 6px;
  outline: none !important;
  border: 2px solid var(--color-danger-700);
}

.input-container .password-icon {
  position: absolute;
  cursor: pointer;
  right: 8px;
  top: 16px;
}

.input-container .password-icon.show {
  width: 14px;
  height: 10px;
  background: url("../../../assets/icons/password-show.svg") center center no-repeat;
  background-size: initial;
}

.input-container .password-icon.hide {
  width: 14px;
  height: 12px;
  background: url("../../../assets/icons/password-hide.svg") center center no-repeat;
  background-size: initial;
}

.input-container.disabled .password-icon {
  cursor: default;
}

.input-container .error-message-container {
  width: calc(100% - 8px);
  padding: 4px;
  margin-top: 4px;
  float: left;
  background-color: var(--color-danger-100);
}

.input-container .error-message-container .warning-icon {
  width: 16px;
  height: 16px;
  float: left;
  background: url("../../../assets/icons/information/information-critical.svg") center center no-repeat;
  background-size: cover;
}

.input-container .error-message-container p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-text-icon-primary);
}