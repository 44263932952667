.input-container {
  width: 300px;
}

.input-container .input-wrapper {
  width: 100%;
  position: relative;
  float: left;
}

.input-container input.text-input {
  background: var(--color-white);
  border: 1px solid var(--color-stroke-300);
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  color: var(--color-text-icon-primary);
  float: left;
  position: relative;
  z-index: 10;
}

.input-container input.text-input.xsmall {
  width: calc(100% - 16px);
  padding: 3px 7px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 4px;
}

.input-container input.text-input.base {
  width: calc(100% - 16px);
  padding: 7px 7px;
  font-size: 16px;
  line-height: 24px;
  border-radius: 8px;
}

.input-container input.text-input.xsmall:focus {
  width: calc(100% - 14px);
  padding: 2px 6px;
  outline: none !important;
  border: 2px solid var(--color-info);
}

.input-container input.text-input.base:focus {
  width: calc(100% - 14px);
  padding: 6px 6px;
  outline: none !important;
  border: 2px solid var(--color-info);
}

.input-container input.text-input::placeholder {
  color: var(--color-text-icon-secondary);
}

.input-container input.text-input:disabled {
  background: var(--color-disabled);
}

.input-container input.text-input.error {
  border: 1px solid var(--color-danger-700);
}

.input-container input.text-input.xsmall.error:focus {
  width: calc(100% - 14px);
  padding: 3px 7px;
  outline: none !important;
  border: 1px solid var(--color-danger-700);
}

.input-container input.text-input.base.error:focus {
  width: calc(100% - 14px);
  padding: 6px 6px;
  outline: none !important;
  border: 2px solid var(--color-danger-700);
}

.input-container .search-icon {
  position: absolute;
  right: 8px;
  top: 8px;
  width: 12px;
  height: 12px;
  background: url("../../../assets/icons/search.svg") center center no-repeat;
  background-size: cover;
  z-index: 10;
}

.input-container .search-list {
  position: relative;
  width: 100%;
}

.input-container .search-list .item-container {
  width: calc(100% + 32px);
  max-height: 212px;
  position: absolute;
  top: -16px;
  left: -16px;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  z-index: 3;
}

.input-container .search-list .item-container .temporary-header {
  width: 100%;
  height: 52px;
  background-color: var(--color-white);
  border-radius: 6px 6px 0px 0px;
  z-index: 1;
}

.input-container .search-list .item-container .matched-items {
  width: 100%;
  max-height: 160px;
  background-color: var(--color-white);
  border-radius: 0px 0px 6px 6px;
  border-top: 1px solid var(--color-stroke-300);
  z-index: 10;
  overflow: auto;
}

.input-container .search-list .matched-items .item {
  width: calc(100% - 32px);
  padding: 8px 16px;
  background: var(--color-white);
  float: left;
  position: relative;
}

.input-container .search-list .matched-items .item:hover {
  background: var(--color-interaction-primary-100);
  cursor: pointer;
}

.input-container .search-list .matched-items .item p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--color-text-icon-primary);
  float: left;
}