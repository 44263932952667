.client-setup-subscription-details-step {
  width: 100%;
  margin-top: 40px;
  float: left;
}

.client-setup-subscription-details-step .subscription-details-form {
  /* width: calc(50% - 32px); */
  width: 460px;
  /* margin: auto; */
}

.client-setup-subscription-details-step .row {
  width: 100%;
  float: left;
  margin-bottom: 24px;
}

.action-buttons-container {
  height: 40px;
  padding: 25px 32px 24px 32px;
  border-top: 1px solid var(--color-stroke-400);
  background-color: var(--color-white);
  position: fixed;
  bottom: 0px;
  margin-left: -32px;
  z-index: 5;
}

.action-buttons-container .left-buttons {
  float: left;
}

.action-buttons-container .right-buttons {
  float: right;
}

.action-buttons-container .right-buttons div {
  margin-right: 8px;
}

.action-buttons-container .right-buttons div:last-child {
  margin-right: 0px;
}