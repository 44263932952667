.subscription-details-container {
  width: 100%;
  float: left;
}

.subscription-details-container .subscription-details-content {
  width: 460px;
  float: left;
  border-top: 1px solid var(--color-neutral-300);
}

.subscription-details-container .subscription-details-content .row {
  width: 100%;
  float: left;
  margin-bottom: 16px;
}

.subscription-details-container .subscription-details-content .title-container {
  width: 100%;
  float: left;
  margin-top: 24px;
}

.subscription-details-container .subscription-details-content .title-container h3 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.32px;
  float: left;
  color: var(--color-text-icon-primary);
}

.subscription-details-container .subscription-details-content .details-form-container {
  width: 100%;
  float: left;
}

.subscription-details-container .subscription-details-content .details-form-container .label {
 width: 200px;
 float: left;
 margin-left: 16px;
}

.subscription-details-container .subscription-details-content .details-form-container .label h4 {
  margin: 2px 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.28px;
  float: left;
  color: var(--color-text-icon-secondary);
}

.subscription-details-container .subscription-details-content .details-form-container .value {
  float: left;
  width: 240px;
 }
 
.subscription-details-container .subscription-details-content .details-form-container .value p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  float: left;
  color: var(--color-text-icon-primary);
}

.subscription-details-container .subscription-details-content .details-note-container {
  width: 100%;
  float: left;
}

.subscription-details-container .subscription-details-content .details-note-container .note-content {
  float: left;
  margin-left: 16px;
 }
 
 .subscription-details-container .subscription-details-content .details-note-container .note-content p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  float: left;
  color: var(--color-text-icon-secondary);
}

.subscription-details-container .subscription-details-content .details-note-container .note-content p a {
  text-decoration: none;
}