.page {
  width: 100%;
  height: 100%;
  float: left;
}

.page .container.with-account-manager-warning {
  margin-top: 65px;
}

.page .container.wide {
  width: calc(100% - 120px);
  padding: 48px 32px;
  float: left;
  margin-left: 56px;
}

.page .container.narrow {
  width: calc(100% - 320px);
  padding: 48px 32px;
  float: left;
  margin-left: 256px;
}

.page-header {
  width: 100%;
  float: left;
}

.page-header .section .row {
  max-width: 100%;
  width: 100%;
  float: left;
  margin-bottom: 8px;
}

.page-header h1 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: -1px;
  float: left;
}

.page-header .section .row .title {
  float: left;
}

.page-body {
  width: 100%;
  float: left;
}

.page-body .row {
  width: 100%;
  float: left;
  margin-bottom: 16px;
}

.page-body .row .graph-section-header {
  width: 100%;
  float: left;
  margin-top: 16px;
}

.page-body .row .graph-section-title {
  float: left;
}

.page-body .row .graph-section-title h2 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -1px;
  float: left;
  color: var(--color-text-icon-primary);
}

.page-body .row .graph-section-note {
  float: left;
  margin: 6px 8px;
}

.page-body .row .graph-section-note p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.24px;
  float: left;
  color: var(--color-text-icon-secondary);
}

.dashboard-banner-container {
  width: 100%;
  float: left;
}

.dashboard-banner-container .dashboard-banner {
  /* width: 1264px; */
  width: 1218px;
  border-radius: 8px;
  border: 1px solid var(--color-neutral-300);
  background: linear-gradient(45deg, #FFFFFF 55%, #8AEAFF 100%);
  padding: 32px 48px;
  float: left;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dashboard-banner-container .dashboard-banner .highlight {
  width: 340px;
  float: left;
}

.dashboard-banner-container .dashboard-banner .highlight h1 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -1px;
  color: var(--color-text-icon-disabled);
}

.dashboard-banner-container .dashboard-banner .highlight h1 span.higlighted-dark {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 37px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -1px;
  color: var(--color-brand-color-primary-dark-blue);
}

.dashboard-banner-container .dashboard-banner .highlight h1 span.dark {
  color: var(--color-brand-color-primary-dark-blue);
}

.dashboard-banner-container .dashboard-banner .cover-graph {
  width: calc(100% - 680px);
  float: left;
}

.dashboard-banner-container .dashboard-banner .cover-stats {
  width: 200px;
  margin-left: 140px;
  float: left;
}

.dashboard-banner-container .dashboard-banner .cover-stats .ratio {
  width: 100%;
  float: left;
}

.dashboard-banner-container .dashboard-banner .cover-stats .ratio .covered {
  float: left;
}

.dashboard-banner-container .dashboard-banner .cover-stats .ratio .covered h1 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 96px;
  font-style: normal;
  font-weight: 700;
  line-height: 90px;
  letter-spacing: -4px;
  color: var(--color-brand-color-primary-dark-blue);
}

.dashboard-banner-container .dashboard-banner .cover-stats .ratio .total {
  float: left;
  margin-top: 53px;
}

.dashboard-banner-container .dashboard-banner .cover-stats .ratio .total h1 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 37px;
  letter-spacing: -1px;
  color: var(--color-brand-color-primary-dark-blue);
}

.dashboard-banner-container .dashboard-banner .cover-stats .description {
  width: 100%;
  float: left;
}

.dashboard-banner-container .dashboard-banner .cover-stats .description p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.28px;;
  color: var(--color-text-icon-secondary);
}

.graph-container {
  padding: 16px 24px;
  float: left;
  background-color: var(--color-white);
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.16);
}

.graph-container .header {
  width: 100%;
  float: left;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--color-neutral-300);

}

.graph-container .header .title {
  float: left;
}

.graph-container .header .title h3 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: var(--color-text-icon-primary);
}

.graph-container .header .tooltip-icon {
  width: 16px;
  height: 16px;
  float: left;
  margin: 4px 0px 4px 8px;
  background: url("../../../assets/icons/information/information-tooltip.svg") no-repeat center center;
  background-size: cover;
  cursor: pointer;
}

.graph-container .body {
  width: 100%;
  float: left;
}

.graph-container .footer {
  width: 100%;
  float: left;
}

.graph-container .footer .note {
  width: 100%;
  float: left;
}

.graph-container .footer .note p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.24px;
  float: left;
  color: var(--color-text-icon-secondary);
}

.graph-container.top-countries-graph-container {
  /* width: 912px; */
  width: 890px;
  min-height: 450px;
  margin-right: 16px;
}

.graph-container.top-countries-graph-container.full-width {
  width: 1266px;
  margin-right: 0px;
}

.graph-container.top-investors-list-container {
  /* width: 332px; */
  width: 310px;
  min-height: 450px;
}

.graph-container.top-investors-list-container .body {
  width: 100%;
  float: left;
  margin: 32px 0px 0px 0px;
}

.graph-container.top-investors-list-container .body .item {
  width: 100%;
  float: left;
  padding: 16px 0px;
  border-bottom: 1px solid var(--color-neutral-300);
}

.graph-container.top-investors-list-container .body .item:first-child {
  padding-top: 0px;
}

.graph-container.top-investors-list-container .body .item:last-child {
  padding-bottom: 0px;
  border-bottom: none;
}

.graph-container.top-investors-list-container .body .item .logo {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 1px solid var(--color-neutral-300);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-right: 12px;
  float: left;
}

.graph-container.top-investors-list-container .body .item .name {
  max-width: 200px;
  margin: 10px 0px;
  margin-right: 12px;
  float: left;
}

.graph-container.top-investors-list-container .body .item .name h4 {
  width: 16px;
  float: left;
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.28px;
  color: var(--color-text-icon-primary);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.graph-container.top-investors-list-container .body .item .name a {
  width: calc(100% - 16px);
  float: left;
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.28px;
  color: var(--color-interaction-primary-700);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: none;
}
  
  

.graph-container.top-investors-list-container .body .item .isc {
  margin: 10px 0px;
  float: right;
}

.graph-container.top-investors-list-container .body .item .isc h4 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--color-success-700);
}






.graph-container.investor-type-graph-container {
  /* width: 622px; */
  width: 600px;
  min-height: 375px;
  margin-right: 16px;
}

.graph-container.agm-participation-graph-container {
  width: 600px;
  min-height: 350px;
  margin-right: 16px;
}

.graph-container.voting-decision-maker-graph-container {
  width: 600px;
  min-height: 350px;
  margin-right: 16px;
}

.graph-container.activist-graph-container {
  width: 600px;
  min-height: 350px;
  margin-right: 16px;
}

.graph-container.esg-focus-graph-container { 
  width: 600px;
  min-height: 350px;
  margin-right: 16px;
}

.graph-container.receptivity-to-activists-graph-container {
  width: 600px;
  min-height: 350px;
}

.graph-container.level-of-insight-graph-container {
  width: 600px;
  min-height: 375px; 
}

.graph-container.proxy-advisors-reliance-graph-container {
  width: 600px;
  min-height: 350px;
}

.graph-container.proxy-advisors-graph-container {
  width: 600px;
  min-height: 350px;
}

.graph-container.esg-main-service-providers-graph-container { 
  width: 600px;
  min-height: 350px;
}