.page {
  width: 100%;
  height: 100%;
  float: left;
}

.page .container.with-account-manager-warning {
  margin-top: 65px;
}

.page .container.wide {
  width: calc(100% - 120px);
  padding: 48px 32px;
  float: left;
  margin-left: 56px;
}

.page .container.narrow {
  width: calc(100% - 320px);
  padding: 48px 32px;
  float: left;
  margin-left: 256px;
}

.research-container {
  width: 100%;
  height: 100%;
  float: left;
  /* margin-bottom: 32px; */
}

.research-container .row {
  max-width: 100%;
  width: 100%;
  height: 100%;
  float: left;
  margin-bottom: 8px;
}

.powerbi-container { 
  width: 100%;
  height: 100%;
}