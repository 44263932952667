.extra-information-area {
  width: 100%;
  float: left;
  margin-bottom: 24px;
  position: relative;
}

.extra-information-area.no-engagements-module {
  margin-bottom: 0px;
}

.extra-information-area .engagements-container {
  width: 900px;
  max-height: 64px;
  padding: 12px 16px;
  float: left;
  background: var(--color-white);
  border: 1px solid var(--color-neutral-300);
  border-radius: 6px;
}

.extra-information-area .engagements-container .engagement-description {
  width: 352px;
  float: left;
  margin-right: 16px;
}

.extra-information-area .engagements-container .engagement-description p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text-icon-disabled);
}

.extra-information-area .engagements-container .engagement-description p a {
  color: var(--color-interaction-primary-700);
  text-decoration: none;
}

.extra-information-area .engagements-container .engagement-item {
  width: 250px;
  float: left;
  padding: 4px;
  background: var(--color-engagement-success-300);
  border-radius: 4px;
  margin-right: 16px;
}

.extra-information-area .engagements-container .engagement-item:last-child {
  margin-right: 0px;
}

.extra-information-area .engagements-container .engagement-item .engagement-icon {
  width: 24px;
  height: 24px;
  float: left;
  background: var(--color-engagement-success-400) url("../../../../assets/icons/mail/mail-engagement-success.svg") no-repeat center center;
  background-size: auto;
  border-radius: 50%;
}

.extra-information-area .engagements-container .engagement-item .engagement-content {
  width: calc(100% - 32px);
  float: left;
  margin-left: 8px;
}

.extra-information-area .engagements-container .engagement-item .engagement-content .label {
  width: 100%;
  float: left;
}

.extra-information-area .engagements-container .engagement-item .engagement-content .label h4 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-text-icon-disabled);
}

.extra-information-area .engagements-container .engagement-item .engagement-content .title {
  width: 100%;
  float: left;
}

.extra-information-area .engagements-container .engagement-item .engagement-content .title p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-interaction-primary-700);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.extra-information-area .engagements-container .engagement-item .engagement-content .title a {
  text-decoration: none;
}

.extra-information-area .engagements-container .engagement-item .engagement-content .date {
  width: 100%;
  float: left;
}

.extra-information-area .engagements-container .engagement-item .engagement-content .date p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text-icon-disabled);
}

.extra-information-area .relationship-status-container {
  width: 300px;
  float: right;
  position: absolute;
  top: -61px;
  right: 0px;
}

.extra-information-area .relationship-status-container .title {
  float: right;
  margin: 0px 16px 0px 0px;
}

.extra-information-area .relationship-status-container .title h4 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: var(--color-text-icon-primary);
}

.extra-information-area .relationship-status-container .value {
  float: right;
  margin-top: -8px;
}

.investor-information-area {
  width: 100%;
  float: left;
  background: var(--color-white);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}

.investor-information-area .section {
  width: calc(100% - 16px);
  float: left;
  position: relative;
  padding: 68px 8px 0px 8px;
}

.investor-information-area .section .upload-investor-logo-container {
  position: absolute;
  top: -218px;
  left: 0px;
  margin: auto;
}

.investor-information-area .section .upload-investor-logo-container .photo {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  margin: auto;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: -1;
  opacity: 0.15;
}

.investor-information-area .section .upload-investor-logo-container label .plus-icon {
  width: 21px;
  height: 21px;
  background: url("../../../../assets/icons/plus/plus-interaction-primary-700.svg") center center no-repeat;
  background-size: cover;
  margin: 25.5px;
  cursor: pointer;
}

.investor-information-area .section .upload-investor-logo-container input {
  display: none;
}

.investor-information-area .section .upload-investor-logo-container h4 {
  width: 100%;
  float: left;
  margin: 8px 0px 0px 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: var(--color-text-icon-secondary);
  text-align: center;
}



.investor-information-area .section .section-title {
  position: absolute;
  top: 32px;
  left: 0px;
  padding: 6px 8px;
  background-color: var(--color-interaction-primary-100);
  border-left: 4px solid var(--color-section-ribbon);
}

.investor-information-area .section .section-title h3 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: var(--color-text-icon-primary);
  float: left;
}

.investor-information-area .section .section-title .tooltip-icon {
  width: 13px;
  height: 13px;
  float: left;
  margin: 2px 0px 1px 8px;
  background: url("../../../../assets/icons/information/information-tooltip.svg") no-repeat center center;
  background-size: cover;
  cursor: pointer;
}

.investor-information-area .section .action-buttons {
  position: absolute;
  top: 32px;
  right: 32px;
}

.investor-information-area .section .action-buttons div {
  margin-right: 8px;
}

.investor-information-area .section .action-buttons div:last-child {
  margin-right: 0px;
}

.investor-information-area .section .content {
  width: calc(100% - 48px);
  float: left;
  padding: 24px;
}

.investor-information-area .section .content.edit-mode {
  background-color: var(--color-interaction-primary-100);
}

.investor-information-area .section .separator {
  width: calc(100% - 48px);
  float: left;
  border-bottom: 1px solid var(--color-neutral-300);
  margin: 8px 24px 0px 24px;
}

.investor-information-area .section:last-child .separator {
  border-bottom: none;
}

.investor-information-area .section .level-of-insights-container {
  width: 300px;
  position: absolute;
  right: 0px;
  top: -114px;
}

.investor-information-area .section .level-of-insights-container.no-engagements-module {
  width: 300px;
  position: absolute;
  right: 0px;
  top: -98px;
  margin-right: 314px;
}

.investor-information-area .section .level-of-insights-container .title {
  float: right;
  margin: 37px 8px 37px 0px;
}

.investor-information-area .section .level-of-insights-container .title h4 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: var(--color-text-icon-primary);
}

.investor-information-area .section .level-of-insights-container .tooltip-icon {
  width: 13px;
  height: 13px;
  float: right;
  margin: 39px 20px 38px 0px;
  background: url("../../../../assets/icons/information/information-tooltip.svg") no-repeat center center;
  background-size: cover;
  cursor: pointer;
}

.investor-information-area .section .level-of-insights-container .value {
  float: right;
  margin: 30px 0px 29px 0px;
}

.investor-information-area .section .level-of-insights-container .value .first-level {
  width: 8px;
  height: 12px;
  float: left;
  margin: 19px 3px 0px 0px;
  background-color: var(--color-neutral-300);
}

.investor-information-area .section .level-of-insights-container .value .first-level.selected {
  /*background-color: #BAE6FD;*/
  background-color: var(--color-danger-700);
}

.investor-information-area .section .level-of-insights-container .value .second-level {
  width: 8px;
  height: 21px;
  float: left;
  margin: 10px 3px 0px 0px;
  background-color: var(--color-neutral-300);
}

.investor-information-area .section .level-of-insights-container .value .second-level.selected {
  /*background-color: #60A5FA;*/
  background-color: var(--color-warning-700);
}

.investor-information-area .section .level-of-insights-container .value .third-level {
  width: 8px;
  height: 31px;
  float: left;
  margin: 0px 9px 0px 0px;
  background-color: var(--color-neutral-300);
}

.investor-information-area .section .level-of-insights-container .value .third-level.selected {
  /*background-color: #1D4ED8;*/
  background-color: var(--color-success-500);
}

.investor-information-area .section .level-of-insights-container .value .text {
  float: left;
  margin: 6px 0px 5px 0px;
}

.investor-information-area .section .level-of-insights-container .value .text p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text-icon-secondary);
}