.tab-container.first-level {
  border-bottom: 1px solid var(--color-stroke-300);
  float: left;
}

.tab-container.first-level .tab-items {
  float: left;
}

.tab-container.first-level .tab-items .item {
  float: left;
  margin-right: 40px;
  cursor: pointer;
}

.tab-container.first-level .tab-items .item:last-child {
  margin-right: 0px;
}

.tab-container.first-level .tab-items .item h4 {
  padding: 12px 0px;
  margin: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: var(--color-text-icon-disabled);
}

.tab-container.first-level .tab-items .item.selected {
  cursor: default;
}

.tab-container.first-level .tab-items .item.selected h4 {
  color: var(--color-interaction-primary-700);
}

.tab-container.first-level .tab-items .item.selected h4::after {
  content: '';
  border-bottom: 2px solid var(--color-interaction-primary-700);
  width: 100%;
  float: left;
  position: relative;
  top: 11px;
}

.tab-container.second-level {
  float: left;
}

.tab-container.second-level .tab-items {
  float: left;
}

.tab-container.second-level .tab-items .item {
  float: left;
  margin-right: 28px;
  cursor: pointer;
}

.tab-container.second-level .tab-items .item:last-child {
  margin-right: 0px;
}

.tab-container.second-level .tab-items .item h4 {
  padding: 8px 12px;
  margin: 0px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text-icon-disabled);
}

.tab-container.second-level .tab-items .item.selected {
  background-color: var(--color-interaction-primary-100);
  border-radius: 8px;
  cursor: default;
}

.tab-container.second-level .tab-items .item.selected h4 {
  color: var(--color-interaction-primary-700);
}