html {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: linear-gradient(0deg, #F9FAFB, #F9FAFB), #FFFFFF;
  overflow: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100%;
  height: 100%;
}

.aqtion-tooltip {
  max-width: 352px;
  background-color: var(--color-white);
  color: #0A0D14;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px -8px 30px rgba(0, 0, 0, 0.15);
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  z-index: 200;
}


:root {
  --color-white: #FFFFFF;
  --color-white-secondary: #FFFFFFBF;

  --color-black: #000000;

  --color-info: #2F6FED;
  --color-section-ribbon: #1D4ED8;

  --color-disabled: #F1F3F9;
  --color-text-icon-primary: #1D2433;
  --color-text-icon-secondary: #1D2433CC;
  --color-text-icon-disabled: #1D2433A6;
  
  --color-danger-100: #FEF1F2;
  --color-danger-200: #FECDD3;
  --color-danger-500: #EA4F44;
  --color-danger-700: #E02D3C;
  --color-danger-800: #BA2532;

  --color-success-100: #EDFDF8;
  --color-success-400: #3EB68F;
  --color-success-500: #4BAC8C;
  --color-success-700: #08875D;
  
  --color-warning-100: #FFF6D6;
  --color-warning-700: #F5A200;

  --color-stroke-300: #E1E6EF;
  --color-stroke-400: #D1D5DB;
  
  --color-neutral-100: #F8F9FC;
  --color-neutral-200: #F1F3F9;
  --color-neutral-300: #E1E6EF;
  --color-neutral-600: #6B7280;
  --color-neutral-800: #23272F;

  --color-engagement-success-300: #ECFDF8;
  --color-engagement-success-400: #B2F5DF;

  --color-gray-50: #F9FAFB;
  --color-gray-200: #E5E7EB;
  --color-gray-500: #6B7280;
  --color-gray-700: #474D5B;

  --color-brand-color-primary-dark-blue: #00263D;
  --color-brand-color-primary-red: #FF5959;
  
  --color-interaction-primary-100: #F0F5FF;
  --color-interaction-primary-200: #DBEAFE;
  --color-interaction-primary-300: #BFDBFE;
  --color-interaction-primary-700: #2563EB;
  --color-interaction-primary-800: #1D4ED8;

  --color-highlight-orange: #FFC68A;
}