.half-donut-chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.graph {
  float: left;
}

.info-box {
  width: 290px;
  float: left;
  margin-top: -115px;
}

.info-box .item {
  width: 100%;
  float: left;
  padding: 8px 0px;
}

.info-box .item:first-child {
  padding-top: 0px;
}

.info-box .item:last-child {
  padding-bottom: 0px;
}

.info-box .item .color {
  width: 12px;
  height: 12px;
  float: left;
  margin: 5px 0px;
  border-radius: 2px;
}

.info-box .item .text {
  float: left;
  margin-left: 8px;
}

.info-box .item .text h4 {
  margin: 0px;
  padding: 0px;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: var(--color-text-icon-secondary);
}

.info-box .item .percentage {
  float: right;
  margin-left: 16px;
}

.info-box .item .percentage h4 {
  margin: 0px;
  padding: 0px;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: var(--color-text-icon-primary);
}