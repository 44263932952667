.contact-us-form {
  width: 100%;
  float: left;
}

.contact-us-form .contact-us-left-container {
  width: 460px;
  float: left;
  margin-right: 32px;
}

.contact-us-form .contact-us-left-container .title {
  width: 100%;
  float: left;
}

.contact-us-form .contact-us-left-container .title h2 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -1px;
}

.contact-us-form .contact-us-left-container .description {
  width: 100%;
  float: left;
  margin-top: 12px;
}

.contact-us-form .contact-us-left-container .description p {
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-icon-primary);
  float: left;
}

.contact-us-form .contact-us-left-container .description ul {
  width: calc(100% - 30px);
  margin-left: -10px;
  float: left;
}

.contact-us-form .contact-us-left-container .description ul li {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-icon-primary);
  float: left;
}

.contact-us-form .contact-us-right-container {
  width: 460px;
  float: left;
  margin-left: 32px;
}

.contact-us-form .contact-us-right-container .row {
  width: 100%;
  float: left;
  margin-bottom: 24px;
}

.contact-us-form .contact-us-right-container .new-attachment-container {
  float: left;
}

.contact-us-form .contact-us-right-container .new-attachment-container .new-attachment-button {
  padding: 8px;
  border: 1px solid var(--color-stroke-400);
  border-radius: 2px;
  float: left;
  cursor: pointer;
}

.contact-us-form .contact-us-right-container .new-attachment-container .new-attachment-button input {
  display: none;
}

.contact-us-form .contact-us-right-container .new-attachment-container .new-attachment-button .new-attachment-icon {
  width: 15px;
  height: 16px;
  background: url("../../../../assets/icons/attachment/attachment-interaction-primary-700.svg") center center no-repeat;
  background-size: inherit;
  float: left;
  margin-right: 8px;
}

.contact-us-form .contact-us-right-container .new-attachment-container .new-attachment-button .new-attachment-text {
  float: left;
  margin: 2px 0px;
}

.contact-us-form .contact-us-right-container .new-attachment-container .new-attachment-button .new-attachment-text h4 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.02em;
  color: var(--color-interaction-primary-700);
}

.contact-us-form .contact-us-right-container .attached-item-container {
  float: left;
  margin: 0px;
}

.contact-us-form .contact-us-right-container .attached-item-container .attached-item {
  padding: 8px;
  border: 1px solid var(--color-stroke-400);
  border-radius: 2px;
  float: left;
}

.contact-us-form .contact-us-right-container .attached-item-container .attached-item .attached-item-icon {
  width: 15px;
  height: 16px;
  background: url("../../../../assets/icons/attachment/attachment-interaction-primary-700.svg") center center no-repeat;
  background-size: inherit;
  float: left;
  margin-right: 8px;
}

.contact-us-form .contact-us-right-container .attached-item-container .attached-item .attached-item-text {
  float: left;
  margin: 2px 0px;
}

.contact-us-form .contact-us-right-container .attached-item-container .attached-item .attached-item-text.clickable {
  cursor: pointer;
}

.contact-us-form .contact-us-right-container .attached-item-container .attached-item .attached-item-text h4 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.02em;
  color: var(--color-interaction-primary-700);
}

.contact-us-form .contact-us-right-container .attached-item-container .attached-item .remove-button {
  width: 8px;
  height: 8px;
  background: url("../../../../assets/icons/cross.svg") center center no-repeat;
  background-size: cover;
  float: left;
  margin: 4px 0px 4px 8px;
  cursor: pointer;
}

.contact-us-form .contact-us-right-container .send-button-container {
  float: right;
}