.step-progress-bar-container {
  padding: 18px 20px;
  background-color: var(--color-white);
  border: 1px solid var(--color-stroke-300);
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  float: left;
}

.step-progress-bar-container .steps {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.step-progress-bar-container .steps .item.initial {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.step-progress-bar-container .steps .item.initial.disabled {
  cursor: default;
  pointer-events: none;
}

.step-progress-bar-container .steps .item.initial .number {
  width: 6px;
  height: 6px;
  background-color: var(--color-stroke-300);
  border-radius: 50%;
  margin-right: 8px;
}

.step-progress-bar-container .steps .item.initial .text p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-text-icon-secondary);
}

.step-progress-bar-container .steps .item.initial.selected .number {
  background-color: var(--color-interaction-primary-700);
}

.step-progress-bar-container .steps .item.initial.selected .text p {
  color: var(--color-interaction-primary-700);
}

.step-progress-bar-container .steps .item.final {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.step-progress-bar-container .steps .item.final.disabled {
  cursor: default;
  pointer-events: none;
}

.step-progress-bar-container .steps .item.final .number {
  width: 6px;
  height: 6px;
  background-color: var(--color-stroke-300);
  border-radius: 50%;
  margin-right: 8px;
}

.step-progress-bar-container .steps .item.final .text p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-text-icon-secondary);
}

.step-progress-bar-container .steps .item.final.selected .number {
  background-color: var(--color-interaction-primary-700);
}

.step-progress-bar-container .steps .item.final.selected .text p {
  color: var(--color-interaction-primary-700);
}

.step-progress-bar-container .steps .item.process {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.step-progress-bar-container .steps .item.process.disabled {
  cursor: default;
  pointer-events: none;
}

.step-progress-bar-container .steps .item.process .number {
  width: 24px;
  height: 24px;
  border: 1px solid var(--color-stroke-300);
  border-radius: 50%;
  margin-right: 8px;
}

.step-progress-bar-container .steps .item.process .number p {
  margin: 0px;
  padding: 4px 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: var(--color-text-icon-disabled);
}

.step-progress-bar-container .steps .item.process .text p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-text-icon-secondary);
}

.step-progress-bar-container .steps .item.process .text p.hidden-width-reference {
  margin-top: -16px;
  visibility: hidden;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.step-progress-bar-container .steps .item.process.warning .number {
  border: 1px solid var(--color-warning-700);
}

.step-progress-bar-container .steps .item.process.warning .number .icon.exclamation-mark {
  width: 24px;
  height: 24px;
  background: url("../../../assets/icons/exclamation-mark/exclamation-mark-warning.svg") center center no-repeat;
  background-size: initial;
}

.step-progress-bar-container .steps .item.process.completed .number {
  border: 1px solid var(--color-success-400);
}

.step-progress-bar-container .steps .item.process.completed .number .icon.success {
  width: 24px;
  height: 24px;
  background: url("../../../assets/icons/tick/tick-success.svg") center center no-repeat;
  background-size: initial;
}

.step-progress-bar-container .steps .item.process.selected .number {
  border: 1px solid var(--color-interaction-primary-700);
}

.step-progress-bar-container .steps .item.process.selected .number p {
  padding: 6px 0px;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: var(--color-interaction-primary-700);
}

.step-progress-bar-container .steps .item.process.selected .text p {
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: var(--color-interaction-primary-700);
}

.step-progress-bar-container .steps .item.process.selected .text p.hidden-width-reference {
  margin-top: -12px;
  visibility: hidden;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
}

.step-progress-bar-container .steps .separator {
  width: 32px;
  height: 1px;
  background-color: var(--color-stroke-300);
  margin: 0px 8px;
}