.bar-chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  margin: 32px 0px;
}

.graph {
  float: left;
}

.info-box {
  width: 460px;
  float: left;
  margin-top: 20px;
}

.info-box .item {
  width: 100%;
  float: left;
  padding: 8px 0px;
}

.info-box .item:first-child {
  padding-top: 0px;
}

.info-box .item:last-child {
  padding-bottom: 0px;
}

.info-box .item .color {
  width: 12px;
  height: 12px;
  float: left;
  margin: 6px 0px;
  border-radius: 2px;
}

.info-box .item .text {
  float: left;
  margin-left: 8px;
}

.info-box .item .text h4 {
  margin: 0px;
  padding: 0px;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-text-icon-secondary);
}

.info-box .item .count {
  float: right;
  margin-left: 16px;
}

.info-box .item .count h4 {
  margin: 0px;
  padding: 0px;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: var(--color-text-icon-primary);
}

.info-box .item .percentage {
  width: 40px;
  float: right;
  margin-left: 16px;
}

.info-box .item .percentage h4 {
  margin: 0px;
  padding: 0px;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-text-icon-secondary);
  text-align: right;
}