.proxy-advisor-contact-list { 
  width: 100%;
  margin-top: -32px;
  float: left;
}

.proxy-advisor-contact-list-buttons {
  float: right;
  margin-bottom: 16px;
}

.proxy-advisor-contact-list-buttons div {
  margin-right: 8px;
}

.proxy-advisor-contact-list-buttons div:last-child {
  margin-right: 0px;
}

.proxy-advisor-contact-list-empty-button-div {
  width: 100%;
  float: right;
  height: 32px;
}
