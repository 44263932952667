.search-and-filter-area {
  width: 100%;
  height: 28px;
  float: left;
  margin: 24px 0px 16px 0px;
}

.search-and-filter-area .export-button {
  float: right;
  margin: -2px 0px 0px 12px;
}

.search-and-filter-area .search-input {
  float: right;
  margin: -1px 0px 0px 12px;
}

.back-button-container { 
  float: left;
  margin-top: -40px;
}

.back-button-container .back-button {
  float: left;
  cursor: pointer;
}

.back-button-container .back-button .back-icon { 
  width: 14px;
  height: 8px;
  background: url("../../assets/icons/arrow-left/arrow-left-text-icon-secondary.svg") center center no-repeat;
  background-size: cover;
  margin: 4px 8px 4px 0px;
  float: left;
}

.back-button-container .back-button h4 { 
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: var(--color-text-icon-secondary);
  float: left;
}

.left-side-container { 
  width: 300px;
  min-height: 40px;
  float: left;
}

.topic-drawer {
  width: 298px;
  float: left;
  border: 1px solid var(--color-neutral-300);
  border-radius: 4px;
}

.topic-drawer .topic-item {
  width: 100%;
  float: left;
  cursor: pointer;
}

.topic-drawer .topic-item .header {
  width: calc(100% - 32px);
  float: left;
  padding: 10px 16px;
  background-color: var(--color-white);
  position: relative;
}

.topic-drawer .topic-item .header.selected::before {
  content: '';
  height: 28px;
  width: 1px;
  position: absolute;
  left: 0px;
  top: 4px;
  background-color: var(--color-brand-color-primary-red);
}

.topic-drawer .topic-item .header:first-child {
  border-radius: 4px 4px 0px 0px;
}

.topic-drawer .topic-item .header:last-child {
  border-radius: 0px 0px 4px 4px;
}

.topic-drawer .topic-item .header .title {
  float: left;
}

.topic-drawer .topic-item .header .title h3 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  color: var(--color-text-icon-secondary);
}

.topic-drawer .topic-item .header .drawer-icon {
  width: 6px;
  height: 6px;
  float: right;
  margin: 5px 0px;
}

.topic-drawer .topic-item .header .drawer-icon .caret {
  width: 6px;
  height: 6px;
  float: left;
  background: url("../../assets/icons/caret-down/caret-down-text-icon-secondary.svg") center center no-repeat;
  background-size: cover;
  cursor: pointer;
}

.topic-drawer .topic-item .body {
  width: 100%;
  float: left;
}

.topic-drawer .topic-item .body .sub-topics-container {
  width: 100%;
  float: left;
}

.topic-drawer .topic-item .body .sub-topics-container .sub-topic-item {
  width: calc(100% - 64px);
  float: left;
  padding: 10px 32px 10px 32px;
  background-color: var(--color-neutral-200);
}

.topic-drawer .topic-item .body .sub-topics-container .sub-topic-item:hover {
  cursor: pointer;
}

.topic-drawer .topic-item .body .sub-topics-container .sub-topic-item h4 {
  max-width: 100%;
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--color-text-icon-secondary);
  float:left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.topic-drawer .topic-item .body .sub-topics-container .sub-topic-item.selected h4 {
  font-weight: 700;
}

.question-list-container {
  width: calc(100% - 324px);
  float: left;
  margin-left: 24px;
}

.question-list-container .question-list {
  width: 100%;
  float: left;
}

.question-list-container .question-list .list-title {
  float: left;
  margin-top: -44px;
}

.question-list-container .question-list .list-title h3 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -1px;
  color: var(--color-text-icon-primary);
}

.question-list-container .question-list .question-item {
  width: 100%;
  float: left;
  margin-bottom: 12px;
  cursor: pointer;
}

.question-list-container .question-list .question-item .header {
  width: 100%;
  float: left;
  border: 1px solid var(--color-neutral-300);
  background-color: var(--color-white);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.question-list-container .question-list .question-item .header .title {
  width: calc(100% - 160px);
  padding: 16px 32px 16px 12px;
  float: left;
}

.question-list-container .question-list .question-item .header .title h4 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.28px;
  color: var(--color-interaction-primary-700);
}

.question-list-container .question-list .question-item .header .title h4 .highlighted {
  background-color: var(--color-highlight-orange);
}

.question-list-container .question-list .question-item .header .icon-container {
  width: 104px;
  height: 24px;
  padding: 0px 12px 0px 0px;
  float: left;
}

.question-list-container .question-list .question-item .header .icon-container .num-of-comments-icon { 
  width: 44px;
  height: 16px;
  float: right;
  margin: 4px 14px 4px 0px;
}

.question-list-container .question-list .question-item .header .icon-container .num-of-comments-icon p {
  margin: 0px 4px 0px 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--color-text-icon-primary);
  float: right;
}

.question-list-container .question-list .question-item .header .icon-container .num-of-comments-icon .num-of-comments { 
  width: 16px;
  height: 16px;
  float: right;
  background: url("../../assets/icons/comment/comment-text-icon-secondary.svg") center center no-repeat;
  background-size: cover;
}

.question-list-container .question-list .question-item .header .icon-container .information-icon { 
  width: 16px;
  height: 16px;
  float: right;
  margin: 4px 14px 4px 0px;
}

.question-list-container .question-list .question-item .header .icon-container .information-icon .information {
  width: 14px;
  height: 14px;
  margin: 1px;
  float: left;
  background: url("../../assets/icons/information/information-tooltip.svg") center center no-repeat;
  background-size: cover;
  cursor: pointer;
}

.question-list-container .question-list .question-item .header .icon-container .drawer-icon { 
  width: 16px;
  height: 16px;
  float: right;
  margin: 4px 0px 4px 0px;
}

.question-list-container .question-list .question-item .header .icon-container .drawer-icon .caret {
  width: 8px;
  height: 14px;
  margin: 1px;
  float: left;
  background: url("../../assets/icons/caret-right/caret-right-text-icon-secondary.svg") center center no-repeat;
  background-size: cover;
  cursor: pointer;
}

.question-details-container {
  width: calc(100% - 324px);
  float: left;
  margin-left: 24px;
}

.question-details-container .question-details {
  width: 100%;
  float: left;
}

.question-details-container .question-details .topic-title {
  float: left;
  margin-top: -43px;
}

.question-details-container .question-details .topic-title h3 {
  margin: -1px 0px 0px 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -1px;
  color: var(--color-text-icon-primary);
}

.question-details-container .question-details .question-text {
  width: 100%;
  float: left;
  margin-bottom: 8px;
}

.question-details-container .question-details .question-text h3 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -1px;
  color: var(--color-text-icon-primary);
}

.question-details-container .question-details .question-description {
  width: 100%;
  float: left;
  margin-bottom: 32px;
}

.question-details-container .question-details .question-description p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-text-icon-secondary);
}

.question-details-container .question-details .question-investor-list {
  width: 100%;
  float: left;
  margin-bottom: 16px;
}

.question-details-container .question-details .question-investor-list .header-item {
  width: calc(100% - 24px);
  float: left;
  padding: 8px 12px;
  background-color: var(--color-neutral-800);
}

.question-details-container .question-details .question-investor-list .header-item .num-of-comments-icon { 
  width: 100%;
  height: 16px;
  float: left;
}

.question-details-container .question-details .question-investor-list .header-item .num-of-comments-icon p {
  margin: 0px 0px 0px 4px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.24px;
  color: var(--color-white);
  float: left;
}

.question-details-container .question-details .question-investor-list .header-item .num-of-comments-icon .num-of-comments { 
  width: 16px;
  height: 16px;
  float: left;
  background: url("../../assets/icons/comment/comment-white.svg") center center no-repeat;
  background-size: cover;
}

.question-details-container .question-details .question-investor-list .question-investor-item {
  width: 100%;
  float: left;
  margin-bottom: 12px;
  cursor: pointer;
}

.question-details-container .question-details .question-investor-list .question-investor-item:last-child {
  margin-bottom: 0px;
}

.question-details-container .question-details .question-investor-list .question-investor-item .header {
  width: 100%;
  float: left;
  border: 1px solid var(--color-neutral-300);
  background-color: var(--color-white);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.question-details-container .question-details .question-investor-list .question-investor-item .header .title {
  width: calc(100% - 160px);
  padding: 16px 32px 16px 12px;
  float: left;
}

.question-details-container .question-details .question-investor-list .question-investor-item .header .title h4 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-text-icon-primary);
}

.question-details-container .question-details .question-investor-list .question-investor-item .header .icon-container {
  width: 104px;
  height: 24px;
  padding: 0px 12px 0px 0px;
  float: left;
}

.question-details-container .question-details .question-investor-list .question-investor-item .header .icon-container .priority-icon { 
  width: 16px;
  height: 16px;
  float: right;
  margin: 4px 14px 4px 0px;
}

.question-details-container .question-details .question-investor-list .question-investor-item .header .icon-container .priority-icon .flag {
  width: 14px;
  height: 14px;
  margin: 1px;
  float: left;
  background: url("../../assets/icons/flag/flag.svg") center center no-repeat;
  background-size: cover;
}

.question-details-container .question-details .question-investor-list .question-investor-item .header .icon-container .drawer-icon { 
  width: 16px;
  height: 16px;
  float: right;
  margin: 4px 0px 4px 0px;
}

.question-details-container .question-details .question-investor-list .question-investor-item .header .icon-container .drawer-icon .caret {
  width: 14px;
  height: 8px;
  margin: 4px 1px;
  float: left;
  background: url("../../assets/icons/caret-down/caret-down-text-icon-secondary.svg") center center no-repeat;
  background-size: cover;
  cursor: pointer;
}

.question-details-container .question-details .question-investor-list .question-investor-item .body {
  width: calc(100% - 64px);
  float: left;
  border: 1px solid var(--color-neutral-300);
  background-color: var(--color-interaction-primary-100);
  padding: 24px 32px;
}

.question-details-container .question-details .question-investor-list .question-investor-item .body p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--color-text-icon-secondary);
}

.question-list .search-topic-block {
  width: 100%;
  float: left;
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid var(--color-neutral-300);
}

.question-list .search-topic-block:last-child {
  padding-bottom: 0px;
  margin-bottom: 0px;
  border-bottom: none;
}

.question-list .search-topic-block .search-topic-title {
  width: 100%;
  float: left;
  margin-bottom: 8px;
}

.question-list .search-topic-block .search-topic-title h4 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.32px;
  color: var(--color-text-icon-primary);
}