.link-button-container {
  float: left;
}

.link-button-container button {
  width: 100%;
  float: left;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0px;
  font-family: 'Montserrat';
  float: left;
  letter-spacing: 0.24px;
  text-align: center;
}

.link-button-container button.xsmall {
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
}

.link-button-container button.base {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.link-button-container button.primary {
  padding-bottom: 3px;
  border-bottom: 1px solid var(--color-interaction-primary-700);
  color: var(--color-interaction-primary-700);
}

.link-button-container button.primary.no-underline {
  border-bottom: none;
}

.link-button-container button.primary:hover {
  padding-bottom: 2px;
  border-bottom: 2px solid var(--color-interaction-primary-800);
  color: var(--color-interaction-primary-800);
}

.link-button-container button.primary.no-underline:hover {
  border-bottom: none;
  padding-bottom: 3px;
}

.link-button-container button.primary:disabled {
  padding-bottom: 4px;
  border-bottom: none;
  color: var(--color-text-icon-disabled);
  cursor: default;
}

.link-button-container button.cancel {
  padding-bottom: 3px;
  border-bottom: 1px solid var(--color-text-icon-primary);
  color: var(--color-text-icon-primary);
}

.link-button-container button.cancel:hover {
  padding-bottom: 2px;
  border-bottom: 2px solid var(--color-text-icon-secondary);
  color: var(--color-text-icon-secondary);
}

.link-button-container button.cancel:disabled {
  padding-bottom: 4px;
  border-bottom: none;
  color: var(--color-text-icon-disabled);
  cursor: default;
}

.link-button-container button.delete {
  padding-bottom: 3px;
  border-bottom: 1px solid var(--color-danger-700);
  color: var(--color-danger-700);
}

.link-button-container button.delete:hover {
  padding-bottom: 2px;
  border-bottom: 2px solid var(--color-danger-800);
  color: var(--color-danger-800);
}

.link-button-container button.delete:disabled {
  padding-bottom: 4px;
  border-bottom: none;
  color: var(--color-text-icon-disabled);
  cursor: default;
}