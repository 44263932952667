.textarea-container {
  width: 300px;
}

.textarea-container label.textarea-label {
  width: 100%;
  padding: 0px 0px 8px 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #1D2433;
  float: left;
}

.textarea-container textarea {
  width: calc(100% - 16px);
  min-height: 120px;
  height: 100%;
  padding: 7px 7px;
  background: var(--color-white);
  border: 1px solid var(--color-stroke-300);
  border-radius: 8px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-icon-primary);
  float: left;
  resize: none;
}

.textarea-container textarea:focus {
  width: calc(100% - 14px);
  padding: 6px 6px;
  outline: none !important;
  border: 2px solid var(--color-info);
}

.textarea-container textarea::placeholder {
  color: var(--color-text-icon-secondary);
}

.textarea-container textarea:disabled {
  background: var(--color-disabled);
}

.textarea-container textarea.error {
  border: 1px solid var(--color-danger-700);
}

.textarea-container textarea.error:focus {
  width: calc(100% - 14px);
  padding: 6px 6px;
  outline: none !important;
  border: 2px solid var(--color-danger-700);
}