.middle-scrollable {
  float: left;
}

.middle-scrollable .scrollable-area {
  float: left;
}

.middle-scrollable .header {
  width: 100%;
  float: left;
}

.middle-scrollable .header .button-area {
  width: calc(100% - 48px);
  float: left;
  padding: 11px 24px;
  background-color: var(--color-interaction-primary-100);
}

.middle-scrollable .header .button-area .icon-button {
  float: right;
  margin-right: 16px;
  cursor: pointer;
}

.middle-scrollable .header .button-area .icon-button .icon.delete {
  width: 18px;
  height: 18px;
  margin: 6px 0px;
  float: left;
  background: url("../../../../assets/icons/trash/trash-danger-500.svg") center center no-repeat;
  background-size: cover;
}

.middle-scrollable .header .button-area .icon-button p {
  margin: 0px 8px 0px 0px;
  padding: 7px 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: var(--color-danger-500);
  float: left;
}

.middle-scrollable .body {
  width: 100%;
  float: left;
}