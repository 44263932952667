.icon-button-container {
  float: left;
}

.icon-button-container button {
  width: 100%;
  float: left;
  border-radius: 6px;
  cursor: pointer;
}

.icon-button-container button.xsmall {
  padding: 7px;
  border-radius: 4px;
}

.icon-button-container button.small {
  padding: 8px;
  border-radius: 6px;
}

.icon-button-container button.base {
  padding: 11px;
}

.icon-button-container button.xsmall .icon-container.plus {
  width: 14px;
  height: 14px;
  float: left;
}

.icon-button-container button.xsmall .icon-container.upload {
  width: 14px;
  height: 14px;
  float: left;
}

.icon-button-container button.small .icon-container.upload {
  width: 14px;
  height: 14px;
  float: left;
}

.icon-button-container button.base .icon-container.upload {
  width: 16px;
  height: 16px;
  float: left;
}

.icon-button-container button.small .icon-container.caret-left {
  width: 10px;
  height: 10px;
  float: left;
  margin: 2px;
}

.icon-button-container button.small .icon-container.caret-right {
  width: 10px;
  height: 10px;
  float: left;
  margin: 2px;
}

.icon-button-container button.primary {
  border: 1px solid var(--color-interaction-primary-700);
  background-color: var(--color-interaction-primary-700);
  color: var(--color-white);
}


.icon-button-container button.primary .icon-container.upload {
  background: url("../../../assets/icons/upload/upload-white.svg") center center no-repeat;
  background-size: contain;
}

.icon-button-container button.primary:hover {
  border: 1px solid var(--color-interaction-primary-800);
  background-color: var(--color-interaction-primary-800);
  color: var(--color-white);
}

.icon-button-container button.primary:disabled {
  border: 1px solid var(--color-neutral-300);
  background-color: var(--color-neutral-300);
  color: var(--color-text-icon-disabled);
  cursor: default;
}

.icon-button-container button.primary:disabled .icon-container.upload {
  background: url("../../../assets/icons/upload/upload-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.icon-button-container button.secondary {
  border: 1px solid var(--color-interaction-primary-300);
  background-color: var(--color-interaction-primary-200);
  color: var(--color-interaction-primary-700);
}

.icon-button-container button.secondary .icon-container.upload {
  background: url("../../../assets/icons/upload/upload-interaction-primary-700.svg") center center no-repeat;
  background-size: contain;
}

.icon-button-container button.secondary .icon-container.plus {
  background: url("../../../assets/icons/plus/plus-interaction-primary-700.svg") center center no-repeat;
  background-size: contain;
}

.icon-button-container button.secondary:hover {
  border: 1px solid var(--color-interaction-primary-300);
  background-color: var(--color-interaction-primary-300);
  color: var(--color-interaction-primary-700);
}

.icon-button-container button.secondary:disabled {
  border: 1px solid var(--color-neutral-200);
  background-color: var(--color-neutral-200);
  color: var(--color-text-icon-disabled);
  cursor: default;
}

.icon-button-container button.secondary:disabled .icon-container.upload {
  background: url("../../../assets/icons/upload/upload-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.icon-button-container button.secondary:disabled .icon-container.plus {
  background: url("../../../assets/icons/plus/plus-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.icon-button-container button.cancel {
  border: 1px solid var(--color-stroke-300);
  background-color: var(--color-white);
  color: var(--color-text-icon-primary);
}

.icon-button-container button.cancel .icon-container.upload {
  background: url("../../../assets/icons/upload/upload-text-icon-primary.svg") center center no-repeat;
  background-size: contain;
}

.icon-button-container button.cancel .icon-container.caret-left {
  background: url("../../../assets/icons/caret-left/caret-left-text-icon-primary.svg") center center no-repeat;
  background-size: contain;
}

.icon-button-container button.cancel .icon-container.caret-right {
  background: url("../../../assets/icons/caret-right/caret-right-text-icon-primary.svg") center center no-repeat;
  background-size: contain;
}

.icon-button-container button.cancel:hover {
  border: 1px solid var(--color-stroke-300);
  background-color: var(--color-neutral-200);
  color: var(--color-text-icon-primary);
}

.icon-button-container button.cancel:disabled {
  border: 1px solid var(--color-neutral-300);
  background-color: var(--color-neutral-200);
  color: var(--color-text-icon-disabled);
  cursor: default;
}

.icon-button-container button.cancel:disabled .icon-container.upload {
  background: url("../../../assets/icons/upload/upload-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}

.icon-button-container button.delete {
  border: 1px solid var(--color-danger-700);
  background-color: var(--color-danger-700);
  color: var(--color-white);
}

.icon-button-container button.delete .icon-container.upload {
  background: url("../../../assets/icons/upload/upload-white.svg") center center no-repeat;
  background-size: contain;
}

.icon-button-container button.delete:hover {
  border: 1px solid var(--color-danger-800);
  background-color: var(--color-danger-800);
  color: var(--color-white);
}

.icon-button-container button.delete:disabled {
  border: 1px solid var(--color-neutral-300);
  background-color: var(--color-neutral-300);
  color: var(--color-text-icon-disabled);
  cursor: default;
}

.icon-button-container button.delete:disabled .icon-container.upload {
  background: url("../../../assets/icons/upload/upload-text-icon-disabled.svg") center center no-repeat;
  background-size: contain;
}