
.label {
  width: 100%;
  float: left;
  margin-bottom: 8px;
}

.label h4 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: var(--color-text-icon-primary);
}

.text p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-icon-secondary);
}

.traffic-light .color-code {
  width: 8px;
  height: 8px;
  float: left;
  margin: 8px 8px 8px 0px;
  border-radius: 50%;
}

.traffic-light .color-code.success {
  background-color: var(--color-success-500);
}

.traffic-light .color-code.warning {
  background-color: var(--color-warning-700);
}

.traffic-light .color-code.danger {
  background-color: var(--color-danger-700);
}

.traffic-light .color-code.unknown {
  background-color: var(--color-gray-700);
}

.traffic-light .color-code.info {
  background-color: var(--color-interaction-primary-700);
}

.text-block {
  width: calc(100% - 18px);
  height: 150px;
  padding: 8px;
  float: left;
  border: 1px solid var(--color-stroke-300);
  border-radius: 8px;
  font-family: 'Montserrat';
  color: var(--color-text-icon-primary);
  overflow: auto;
  background-color: var(--color-white);
}

.text-block.short {
  height: 197px;
}

.text-block.long {
  height: 312px;
}

.text-block p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-icon-secondary);
}

.new-attachment-container {
  float: left;
}

.new-attachment-container .new-attachment-icon-button {
  float: left;
  padding: 8px;
  border-radius: 2px;
  border: 1px solid var(--color-interaction-primary-300);
  background-color: var(--color-interaction-primary-200);
  color: var(--color-interaction-primary-700);
  cursor: pointer;
}

.new-attachment-container .new-attachment-icon-button:hover {
  background-color: var(--color-interaction-primary-300);
}

.new-attachment-container .new-attachment-icon-button input {
  display: none;
}

.new-attachment-container .new-attachment-icon-button .new-attachment-icon {
  width: 14px;
  height: 14px;
  background: url("../../../assets/icons/plus/plus-interaction-primary-700.svg") center center no-repeat;
  background-size: cover;
  float: left;
}

.new-attachment-container .new-attachment-button {
  padding: 8px;
  border: 1px solid var(--color-stroke-400);
  border-radius: 2px;
  float: left;
  cursor: pointer;
}

.new-attachment-container .new-attachment-button input {
  display: none;
}

.new-attachment-container .new-attachment-button .new-attachment-icon {
  width: 15px;
  height: 16px;
  background: url("../../../assets/icons/attachment/attachment-interaction-primary-700.svg") center center no-repeat;
  background-size: inherit;
  float: left;
  margin-right: 8px;
}

.new-attachment-container .new-attachment-button .new-attachment-text {
  float: left;
  margin: 2px 0px;
}

.new-attachment-container .new-attachment-button .new-attachment-text h4 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.02em;
  color: var(--color-interaction-primary-700);
}

.attached-item-container {
  float: left;
  margin: 0px 8px 8px 0px;
}

.attached-item-container .attached-item {
  padding: 8px;
  border: 1px solid var(--color-stroke-400);
  border-radius: 2px;
  float: left;
}

.attached-item-container .attached-item .attached-item-icon {
  width: 15px;
  height: 16px;
  background: url("../../../assets/icons/attachment/attachment-interaction-primary-700.svg") center center no-repeat;
  background-size: inherit;
  float: left;
  margin-right: 8px;
}

.attached-item-container .attached-item .attached-item-text {
  float: left;
  margin: 2px 0px;
}

.attached-item-container .attached-item .attached-item-text.clickable {
  cursor: pointer;
}

.attached-item-container .attached-item .attached-item-text h4 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.02em;
  color: var(--color-interaction-primary-700);
}

.attached-item-container .attached-item .remove-button {
  width: 8px;
  height: 8px;
  background: url("../../../assets/icons/cross.svg") center center no-repeat;
  background-size: cover;
  float: left;
  margin: 4px 0px 4px 8px;
  cursor: pointer;
}