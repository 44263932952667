.client-setup-investor-list-step {
  width: 100%;
  float: left;
  margin-top: 40px;
}

.client-setup-investor-list-step .investor-table-top-container {
  width: 100%;
  float: left;
  margin-bottom: 24px;
}

.client-setup-investor-list-step .investor-table-top-container div {
  float: right;
}

.client-setup-investor-list-step .investor-table-bottom-container {
  width: 100%;
  float: left;
  margin-top: 24px;
}

.client-setup-investor-list-step .investor-table-bottom-container div {
  float: right;
}

.client-setup-investor-list-step .table-container.has-error {
  opacity: 0.3;
}

.client-setup-investor-list-step .missing-data-error {
  width: 100%;
  height: calc(100% - 120px);
  position: absolute;
  background-color: transparent;
}

.client-setup-investor-list-step .missing-data-error .error-box {
  width: 400px;
  padding: 24px;
  margin: auto;
  border: 1px solid var(--color-danger-200);
  border-radius: 8px;
  background-color: var(--color-danger-100);
  margin-top: 49px;
}

.client-setup-investor-list-step .missing-data-error .error-box .title {
  width: 100%;
  float: left;
  margin-bottom: 7px;
}

.client-setup-investor-list-step .missing-data-error .error-box .icon {
  width: 15px;
  height: 16px;
  float: left;
  margin-right: 8px;
}

.client-setup-investor-list-step .missing-data-error .error-box .icon.lock {
  background: url("../../../../assets/icons/lock/lock-danger.svg") center center no-repeat;
  background-size: cover;
}

.client-setup-investor-list-step .missing-data-error .error-box h3 {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: var(--color-text-icon-primary);
}

.client-setup-investor-list-step .missing-data-error .error-box .text {
  width: 100%;
  float: left;
  margin-top: 7px;
}

.client-setup-investor-list-step .missing-data-error .error-box p {
  margin: 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-black);
}

.action-buttons-container {
  height: 40px;
  padding: 25px 32px 24px 32px;
  border-top: 1px solid var(--color-stroke-400);
  background-color: var(--color-white);
  position: fixed;
  bottom: 0px;
  margin-left: -32px;
  z-index: 15;
}

.action-buttons-container .left-buttons {
  float: left;
}

.action-buttons-container .right-buttons {
  float: right;
}

.action-buttons-container .right-buttons div {
  margin-right: 8px;
}

.action-buttons-container .right-buttons div:last-child {
  margin-right: 0px;
}