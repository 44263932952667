.page {
  width: 100%;
  height: 100%;
  float: left;
}

.page .container.wide {
  width: calc(100% - 120px);
  padding: 48px 32px;
  float: left;
  margin-left: 56px;
}

.page .container.narrow {
  width: calc(100% - 320px);
  padding: 48px 32px;
  float: left;
  margin-left: 256px;
}

.client-details-container {
  width: 100%;
  float: left;
  position: relative;
}

.client-details-container .client-details-header {
  width: 100%;
  margin: 16px 0px 6px 0px;
  float: left;
}

.client-details-container .client-details-header .logo {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  float: left;
  margin-right: 16px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--color-white);
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
}

.client-details-container .client-details-header .logo {
  float: left;
}

.client-details-container .client-details-header .title h1 {
  margin: 24px 0px;
  padding: 0px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -1px;
  float: left;
}

.client-details-container .client-details-header .search-box {
  float: right;
  margin: 22px 0px;
}

.client-details-container .client-details-body {
  width: 100%;
  float: left;
  margin-top: 40px;
}